import { QueryResult, useQuery } from '@apollo/client';
import { GET_ORGANIZATIONS_BY_IDS, GET_ORGS } from './queries';
import { IApiOrganization, IApiGetBuyersData, IApiGetBuyersVars, DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from './types';
import { useMemo } from 'react';
import { useFeatureFlag, FeatureFlag } from 'src/helpers/featureFlag';
import { getBuyerLink } from './helpers';

export function useGetOrganisationsByIds(
  organisationIds: string[],
  options?: {
    skip?: boolean;
    limit?: number;
  }
): Omit<
  QueryResult<{ getOrganisationsByIds: IApiOrganization[] }, { organisationIds: string[]; resultLimit?: number }>,
  'data'
> & {
  data: IApiOrganization[];
} {
  const query = useQuery<
    { getOrganisationsByIds: IApiOrganization[] },
    { organisationIds: string[]; resultLimit?: number }
  >(GET_ORGANIZATIONS_BY_IDS, {
    variables: options?.limit ? { organisationIds, resultLimit: options?.limit } : { organisationIds },
    skip: options?.skip || !organisationIds.length
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data
        ? query.data.getOrganisationsByIds
        : query.previousData
        ? query.previousData.getOrganisationsByIds
        : []
    }),
    [query]
  );
}

export function useGetOrgs(searchPhrase: string): Omit<QueryResult<IApiGetBuyersData, IApiGetBuyersVars>, 'data'> & {
  data: IApiOrganization[];
  fetchingMore: boolean;
} {
  const page = DEFAULT_PAGE;
  const pageSize = DEFAULT_PAGE_SIZE;

  const query = useQuery<IApiGetBuyersData, IApiGetBuyersVars>(GET_ORGS, {
    variables: { resultLimit: pageSize, resultOffset: (page - 1) * pageSize, searchPhrase },
    skip: !searchPhrase || searchPhrase.length < 4
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data ? query.data.findOrganisationsByNameOrId : [],
      fetchingMore: query.networkStatus === 3
    }),
    [query]
  );
}

export function useOrganisationById(orgId?: string): { data?: IApiOrganization; loading: boolean } {
  const { data: orgs, loading } = useGetOrganisationsByIds(orgId ? [orgId] : []);

  return useMemo(() => ({ data: orgs.find(org => org.organisationNumber === orgId), loading }), [loading, orgId, orgs]);
}

export function useLinkToBuyer(id?: string): string | undefined {
  const isBuyerPageAvailable = useFeatureFlag(FeatureFlag.AnalyticsOrganisationProfiles);
  const { data } = useOrganisationById(id);

  return useMemo(
    () => (id && isBuyerPageAvailable && !!data ? getBuyerLink(id) : undefined),
    [data, id, isBuyerPageAvailable]
  );
}

import React, { FC } from 'react';
import styles from 'src/pages/TendersPage/TendersList/EmptyMessage/EmptyMatchingProfilesList/index.module.scss';
import { useTranslation } from 'react-i18next';
import LearnMoreLink from 'src/shared/LearnMoreLink';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { EmptyFilter } from 'src/common';

export const EmptyExpiringContractResults: FC = () => {
  const { t, i18n } = useTranslation();
  const isKnowledgeBankFeature = useFeatureFlag(FeatureFlag.KnowledgeBank);

  return (
    <EmptyFilter>
      {isKnowledgeBankFeature && (
        <div className={styles.container}>
          <LearnMoreLink
            title={t('ExpiringContracts.expiringContractsLearnMore')}
            href={`${import.meta.env.VITE_HELPDOCS_DOMAIN}${i18n.language}/link_placeholder`}
          />
        </div>
      )}
    </EmptyFilter>
  );
};

export default EmptyExpiringContractResults;

import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { isFieldUrl, ITenderBox } from 'src/models/procurements/Tender/types';
import { Tooltip } from 'src/common';
import { Link } from 'react-router-dom';
import { useLinkToBuyer } from 'src/models/organisations/hooks';
import { BlockEmpty } from 'src/shared/Blocks';
import { toBuyerName, toBuyerOrgId } from 'src/models/procurements/helpers';
import { MultiBuyerBoxesProps } from '../type';

interface Props extends MultiBuyerBoxesProps {
  buyerBox?: ITenderBox;
}
export const ReadOnlyBuyer: FC<Props> = ({ buyerBox, linkBox, className, noLink, size = 'm', noTooltip }) => {
  const buyerName = useMemo(() => toBuyerName(buyerBox), [buyerBox]);
  const buyerOrgId = useMemo(() => toBuyerOrgId(buyerBox), [buyerBox]);
  const buyerLinkExternal = useMemo(
    () => (!noLink && linkBox && isFieldUrl(linkBox.firstField) ? linkBox.firstField.url ?? undefined : undefined),
    [linkBox, noLink]
  );
  const buyerLinkInternal = useLinkToBuyer(!noLink ? buyerOrgId : undefined);

  return buyerName ? (
    <div className={classNames(styles.buyerInfo, { [styles.small]: size === 's' }, className)}>
      <Tooltip title={!noTooltip ? buyerName : undefined}>
        {buyerLinkInternal ? (
          <Link to={buyerLinkInternal} className={styles.buyerLink}>
            <FontAwesomeIcon icon={faUpRightFromSquare} className={styles.linkIcon} />
            <span className={styles.buyerName}>{buyerName}</span>
          </Link>
        ) : buyerLinkExternal ? (
          <a className={styles.buyerLink} rel="noopener noreferrer" target="_blank" href={buyerLinkExternal}>
            <FontAwesomeIcon icon={faUpRightFromSquare} className={styles.procurerLinkIcon} />
            <span className={styles.buyerName}>{buyerName}</span>
          </a>
        ) : (
          <span className={styles.buyerName}>{buyerName}</span>
        )}
      </Tooltip>
    </div>
  ) : (
    <BlockEmpty />
  );
};

export default ReadOnlyBuyer;

import React, { FC, useCallback, useMemo, useState } from 'react';
import { ITenderBoxDataSource } from 'src/models/procurements/Tender/types';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { usePreviewFile, useTenderData } from 'src/models/procurements/Tender/hooks';
import DocumentViewer from 'src/shared/DocumentViewer';
import { useParams } from 'react-router-dom';
import { useBoxCoordinates } from 'src/pages/TenderPage/DocumentSearch/hooks';
import { IHighlight } from 'react-pdf-highlighter';
import { cleanBoxId, toPdfHighlightsData } from 'src/shared/DocumentViewer/helpers';
import { trackViewDocumentPreview } from 'src/segment/events';

export interface IProps {
  dataSource: ITenderBoxDataSource;
  boxId: string;
}

export const BoxDataSource: FC<IProps> = ({ dataSource, boxId }) => {
  const { filePath, fileName, fileExt } = dataSource;
  const { id: procurementId } = useParams<{ id: string }>();
  const { url, loading, previewFile, closePreview } = usePreviewFile(procurementId);
  const [getCoordinates, { loading: loadingCoords }] = useBoxCoordinates();
  const [highlights, setHighlights] = useState<IHighlight[]>([]);

  const { data } = useTenderData();
  const sourceName = useMemo(() => {
    return fileExt ? `${fileName}.${fileExt}` : fileName;
  }, [fileExt, fileName]);

  const onClick = useCallback(async () => {
    const coords = await getCoordinates({
      variables: { boxId: cleanBoxId(boxId), procurementId: procurementId ?? '' }
    });
    if (coords.data && coords.data?.boxCoordinates) {
      const { headlinePosition, contentPosition } = coords.data?.boxCoordinates;
      const pdfHighlights = toPdfHighlightsData(headlinePosition, contentPosition);
      setHighlights(pdfHighlights);
    }
    if (!loading) {
      previewFile(filePath);
      trackViewDocumentPreview(
        data?.bid?.id ? 'Boxes - Customer report page' : 'Boxes - Details page',
        procurementId ? [procurementId] : undefined
      );
    }
  }, [boxId, data?.bid?.id, filePath, getCoordinates, loading, previewFile, procurementId]);

  return (
    <>
      <button className={styles.source} onClick={onClick}>
        <span className={classNames(styles.sourceIcon, { [styles.isActive]: loading })}>
          <FontAwesomeIcon icon={faFileMagnifyingGlass} />
        </span>
        <span className={styles.sourceName}>{sourceName}</span>
      </button>
      {url && (
        <DocumentViewer
          onClose={closePreview}
          title={sourceName}
          url={url}
          loading={loading || loadingCoords}
          highlights={highlights}
        />
      )}
    </>
  );
};

export default BoxDataSource;

import React, { FC, useMemo, useState } from 'react';
import { TooltipWithBounds } from '@visx/tooltip';
import { formatDate, getDatesFromDuration, toDateRangeStr, toDayJs } from 'src/helpers/dates';
import { formatCurrencyValue } from 'src/models/procurements/Tender/helpers';
import { CumulativeTransaction, PeriodTransaction } from 'src/models/procurements/Billing/types';
import { getSupplierNameByOrgId } from 'src/models/organisations';
import { ChartContainer, DateRangeBar, LineGroup, XYChart } from 'src/common/Charts';
import { useChartTooltip } from 'src/common/Charts/hooks';
import { createColorScale } from 'src/common/Charts/helpers';
import HoverTooltip from './Tooltip';
import { BillingChartProps, SupplierCumulativeValue } from '../types';
import HoverTooltipCumulative from '../TooltipCumulative';
import MissingDataAlert from '../MissingDataAlert';
import styles from '../index.module.scss';
import { useTranslation } from 'react-i18next';
import { SupplierLink } from 'src/shared';
import { useLanguage } from 'src/common';
import { CHART_DEFAULT_MARGIN } from 'src/common/Charts/types';

const BillingCumulativeLineChart: FC<BillingChartProps> = ({
  data,
  vars,
  loading,
  error,
  className,
  displaySupplierLimits
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useChartTooltip<PeriodTransaction>();
  const [cumulativeIndex, setCumulativeIndex] = useState<number | undefined>(undefined);
  const {
    transactions = [],
    cumulativeTransactions = [],
    buyerCoverageGaps = [],
    currency,
    organisations: suppliers = []
  } = data ?? {};
  const { periodType: displayView = 'month', dateFrom, dateTo } = vars ?? {};
  const yDomain: [number, number] = useMemo(
    () => [0, Math.max(...transactions.map(d => d.cumulativeValue))],
    [transactions]
  );
  const xDomain = useMemo(
    () => (dateFrom && dateTo ? getDatesFromDuration([dateFrom, dateTo], displayView) : []),
    [dateFrom, dateTo, displayView]
  );
  const formatPeriod = (date: string): string => formatDate(date, displayView, { showMonthName: true });
  const colorScale = useMemo(() => createColorScale(suppliers.map(s => s.organisationNumber)), [suppliers]);
  const desc = useMemo(() => (xDomain.length ? `(${toDateRangeStr(xDomain, t)})` : undefined), [t, xDomain]);

  // Find last cumulative value for all suppliers
  const supplierCumulativeValues: SupplierCumulativeValue[] = useMemo(() => {
    if (!transactions.length) return [];
    return suppliers.map(s => {
      const data = [...transactions]
        .filter(tr => tr.supplierOrgId === s.organisationNumber)
        .sort((a, b) => {
          return a.cumulativeValue - b.cumulativeValue;
        });
      return {
        supplierOrgId: s.organisationNumber,
        cumulativeValue: data[data.length - 1]?.cumulativeValue ?? 0,
        lastPeriodWithValue: data[data.length - 1]?.period ?? null
      };
    });
  }, [suppliers, transactions]);

  return (
    <ChartContainer
      title={t('Tenders.Billing.CumulativeLineChart.title')}
      desc={desc}
      className={className}
      loading={loading}
      error={error}
    >
      {({ width }) => (
        <>
          <MissingDataAlert buyerCoverageGaps={buyerCoverageGaps} displaySupplierLimits={displaySupplierLimits} />
          <XYChart
            width={width}
            height={640}
            xDomain={xDomain}
            yDomain={yDomain}
            colorScale={colorScale}
            formatX={formatPeriod}
            formatY={value => formatCurrencyValue(value as number, language)}
            formatLegend={key => getSupplierNameByOrgId(suppliers, key)}
            renderLegend={({ datum }, node) => (
              <SupplierLink orgId={datum} orgName={getSupplierNameByOrgId(suppliers, datum)}>
                {node}
              </SupplierLink>
            )}
            margin={{ ...CHART_DEFAULT_MARGIN, left: 70 }}
          >
            <DateRangeBar<CumulativeTransaction>
              data={cumulativeTransactions}
              getDateRanges={d => [d.from, d.to]}
              onMouseLeave={hideTooltip}
              onMouseEnter={({ tooltipTop, tooltipLeft }, i) => {
                setCumulativeIndex(i);
                showTooltip({ tooltipTop, tooltipLeft });
              }}
            />
            <LineGroup<PeriodTransaction>
              data={transactions}
              getLegendKey={d => d.supplierOrgId}
              getYValue={d => d.cumulativeValue}
              getXValue={d => d.period}
              onMouseLeave={hideTooltip}
              onMouseEnter={({ tooltipData, tooltipTop, tooltipLeft }) => {
                showTooltip({ tooltipData, tooltipTop, tooltipLeft });
              }}
            />
          </XYChart>
          {tooltipOpen && (tooltipData || cumulativeIndex !== undefined) && (
            <TooltipWithBounds top={tooltipTop} left={tooltipLeft} className={styles.tooltip}>
              {tooltipData ? (
                <HoverTooltip
                  currency={currency}
                  period={formatPeriod(tooltipData.period)}
                  transactions={transactions.filter(d => d.period === tooltipData.period)}
                  suppliers={suppliers}
                  supplierCumulativeValues={supplierCumulativeValues.filter(d =>
                    toDayJs(d.lastPeriodWithValue).isSameOrBefore(toDayJs(tooltipData.period))
                  )}
                  colorScale={colorScale}
                />
              ) : (
                cumulativeIndex !== undefined && (
                  <HoverTooltipCumulative
                    currency={currency}
                    data={cumulativeTransactions[cumulativeIndex]}
                    dataIndex={cumulativeIndex}
                  />
                )
              )}
            </TooltipWithBounds>
          )}
        </>
      )}
    </ChartContainer>
  );
};

export default BillingCumulativeLineChart;

import { DEFAULT_TENDER_NAV_VIEW, PagePreferences, UserPreferences, PageName, PageView } from './types';
import { MutationTuple, useMutation, useQuery, QueryResult, ApolloError, MutationUpdaterFn } from '@apollo/client';
import { ApiMeUser, isPaidUser, useApp } from 'src/models/auth';
import { UPDATE_PAGE_PREFS, UPDATE_ME_PREFS, GET_ME_PREFERENCES } from './queries';
import { useMemo, useCallback } from 'react';
import { groupByPageName } from './helpers';
import { useLocation } from 'react-router-dom';
import { Paths } from 'src/pages/paths';
import { ApiMeResponse, useMe } from 'src/common';

export function useMyPreferences(): QueryResult<ApiMeResponse> {
  const { user } = useApp();
  return useQuery(GET_ME_PREFERENCES, { skip: !isPaidUser(user) });
}

interface UpdatePageResponse {
  __typename: 'Mutation';
  updatePageSettings: PagePreferences & {
    __typename: 'PageSettings';
  };
}
interface UpdatePageInput
  extends Pick<
    PagePreferences,
    'view' | 'columns' | 'tab' | 'marketDashboardFilters' | 'description' | 'supplierProfile'
  > {}
export function useUpdatePagePrefs(): MutationTuple<UpdatePageResponse, PagePreferences> {
  return useMutation<UpdatePageResponse, PagePreferences>(UPDATE_PAGE_PREFS);
}
function getUpdateCacheOnUpdatePagePrefs(email: string): MutationUpdaterFn<UpdatePageResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const { updatePageSettings: result } = data;
    const userRef = cache.identify({ __typename: 'User', email });
    const pageRef = cache.identify({ __typename: 'PageSettings', id: result.id });
    cache.modify({
      id: userRef,
      fields: {
        settings(cached, { readField }) {
          const pages = readField({ fieldName: 'pages', from: cached });
          const alreadyExists = ((pages as []) || []).some(
            page => page && readField({ fieldName: 'id', from: page }) === result.id
          );
          return alreadyExists
            ? cached
            : {
                ...cached,
                pages: [...(cached?.pages || []), { __ref: pageRef }]
              };
        }
      }
    });
  };
}

export function usePagePreferences(
  pageName?: PageName
): [PagePreferences | undefined, (page: UpdatePageInput) => void, { loading: boolean; error?: ApolloError }] {
  const { data: meData } = useMe();
  const email = meData?.email;
  const { data: prefsData, loading: prefsDataLoading, error: prefsDataError } = useMyPreferences();
  const [updatePage, { loading: updatePageLoading, error: updatePageError }] = useUpdatePagePrefs();
  const { pathname } = useLocation();
  const preferences = prefsData?.getMe.account?.settings;
  const groupedPages = useMemo(
    () =>
      preferences && preferences.pages && preferences.pages.length ? groupByPageName(preferences.pages) : undefined,
    [preferences]
  );

  const currentPageName = useMemo(() => {
    if (pageName) {
      return pageName;
    }
    if (pathname.includes(Paths.ALL_TENDERS)) {
      return PageName.allTenders;
    } else if (pathname.includes(Paths.MONITORING)) {
      return PageName.mpTenders;
    } else if (pathname.includes(Paths.EXPIRING_CONTRACTS_ROUTE)) {
      return PageName.expiringContracts;
    } else if (pathname.includes(Paths.BUYERS_PAGE)) {
      return PageName.buyerProfiles;
    } else if (pathname.includes(Paths.SUPPLIER_PAGE.split('/')[0])) {
      return PageName.supplierProfile;
    } else if (pathname.includes(Paths.STARRED_TENDERS)) {
      return PageName.starredTenders;
    } else if (pathname.includes(Paths.CALL_OFFS)) {
      return PageName.callOffs;
    } else if (pathname.includes(Paths.BIDSPACES) && pathname.split(Paths.BIDSPACES)[1]) {
      return PageName.bids;
    } else if (pathname.includes(Paths.BIDSPACES) && !pathname.split(Paths.BIDSPACES)[1]) {
      return PageName.bidSpaces;
    } else if (pathname.includes(Paths.MARKET_DASHBOARD)) {
      return PageName.marketDashboard;
    } else {
      return;
    }
  }, [pageName, pathname]);

  const currentPage = useMemo(
    () => (groupedPages && currentPageName ? groupedPages[currentPageName] : undefined),
    [currentPageName, groupedPages]
  );

  const updatePageFn = useCallback(
    (page: UpdatePageInput) => {
      if (currentPageName && email) {
        updatePage({
          variables: { id: currentPageName, ...page },
          update: getUpdateCacheOnUpdatePagePrefs(email),
          optimisticResponse: {
            __typename: 'Mutation',
            updatePageSettings: currentPage
              ? { __typename: 'PageSettings', ...currentPage, ...page }
              : { __typename: 'PageSettings', id: currentPageName, ...page }
          }
        });
      }
    },
    [currentPage, currentPageName, email, updatePage]
  );

  return useMemo(
    () => [
      currentPage,
      updatePageFn,
      { loading: prefsDataLoading || updatePageLoading, error: prefsDataError || updatePageError }
    ],
    [currentPage, prefsDataError, prefsDataLoading, updatePageError, updatePageFn, updatePageLoading]
  );
}

interface UpdateSidebarResponse {
  __typename: 'Mutation';
  updateSettings: ApiMeUser & {
    __typename: 'User';
  };
}
interface UpdateSidebarInput extends Pick<UserPreferences, 'minimal'> {}
export function useUpdateSidebarPrefs(): (minimal: boolean) => void {
  const { data: paidUser } = useMe();
  const [updateSidebarPrefsFn] = useMutation<UpdateSidebarResponse, UpdateSidebarInput>(UPDATE_ME_PREFS);
  const { data: prefsData } = useMyPreferences();
  const mePrefs = prefsData?.getMe.account?.settings;

  return useCallback(
    (minimal: boolean) => {
      if (paidUser) {
        const { userName, ...userFields } = paidUser;
        updateSidebarPrefsFn({
          variables: { minimal },
          update: getUpdateCacheOnUpdateSidebarPrefs(minimal, paidUser.email),
          optimisticResponse: {
            __typename: 'Mutation',
            updateSettings: {
              __typename: 'User',
              ...userFields,
              settings: { pages: mePrefs ? mePrefs.pages : undefined, minimal }
            }
          }
        }).catch(error => {
          console.warn({ error });
        });
      }
    },
    [mePrefs, paidUser, updateSidebarPrefsFn]
  );
}
export function getUpdateCacheOnUpdateSidebarPrefs(
  minimal: boolean,
  email: string
): MutationUpdaterFn<UpdateSidebarResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const userRef = cache.identify({ __typename: 'User', email });
    cache.modify({
      id: userRef,
      fields: {
        settings(cached) {
          return {
            ...cached,
            minimal
          };
        }
      }
    });
  };
}

export function useTendersNavViewState(): [PageView, PageView, PageView, (page: PageName, view: PageView) => void] {
  const [navPrefMonitoring, updateNavMonitoring] = usePagePreferences(PageName.tenderNavMonitoring);
  const [navPrefProjectBid, updateNavProjectBid] = usePagePreferences(PageName.tenderNavProjectBid);
  const [navPrefAnalytics, updateNavAnalytic] = usePagePreferences(PageName.tenderNavAnalytics);

  const monitoringNavView = useMemo(() => {
    return navPrefMonitoring?.view ? navPrefMonitoring.view : DEFAULT_TENDER_NAV_VIEW;
  }, [navPrefMonitoring]);

  const projectBidNavView = useMemo(() => {
    return navPrefProjectBid?.view ? navPrefProjectBid.view : DEFAULT_TENDER_NAV_VIEW;
  }, [navPrefProjectBid]);

  const analyticsNavView = useMemo(() => {
    return navPrefAnalytics?.view ? navPrefAnalytics.view : DEFAULT_TENDER_NAV_VIEW;
  }, [navPrefAnalytics]);

  const setTenderNavView = useCallback(
    (page: PageName, view: PageView) => {
      switch (page) {
        case PageName.tenderNavMonitoring:
          updateNavMonitoring({ view });
          break;
        case PageName.tenderNavProjectBid:
          updateNavProjectBid({ view });
          break;
        case PageName.tenderNavAnalytics:
          updateNavAnalytic({ view });
          break;
      }
    },
    [updateNavAnalytic, updateNavMonitoring, updateNavProjectBid]
  );

  return useMemo(() => {
    return [monitoringNavView, projectBidNavView, analyticsNavView, setTenderNavView];
  }, [analyticsNavView, monitoringNavView, projectBidNavView, setTenderNavView]);
}

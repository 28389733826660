import { BidItemType } from '@tendium/prom-types/tender';
import { safeCallSegment } from '../helpers';

export function trackConnectItemsToBid(
  itemsIds: readonly string[],
  itemType: BidItemType,
  eventSource: string,
  bidId: string
): void {
  safeCallSegment(() => {
    analytics.track(`Connect ${itemType === BidItemType.Procurement ? `procurements` : `call-offs`} to Bid`, {
      itemsIds,
      eventSource,
      bidId
    });
  });
}

// BT: Generate task (second button)
export function trackBTGenerateTask(bidId: string): void {
  safeCallSegment(() => {
    analytics.track('BT generate task', {
      bidId
    });
  });
}

// BT: Create group
export function trackBTCreateGroup(bidId: string): void {
  safeCallSegment(() => {
    analytics.track('BT create group', {
      bidId
    });
  });
}

// BT: Delete group
export function trackBTDeleteGroup(groupId: string, bidId?: string): void {
  safeCallSegment(() => {
    analytics.track('BT delete group', {
      bidId,
      groupId
    });
  });
}

// BT: generate preview document
export function trackBTGeneratePreviewDocument(procurement: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('BT generate preview document', {
      procurement
    });
  });
}

// BT: generate download document
export function trackBTGenerateDownloadDocument(procurement: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('BT generate download document', {
      procurement
    });
  });
}

// Change bid stage
export function trackChangeBidStage(
  workspace: { id: string; name?: string },
  categoryName: string,
  eventSource: string
): void {
  safeCallSegment(() => {
    analytics.track('WS change bid stage', {
      workspace,
      categoryName,
      eventSource
    });
  });
}

// Reject bid
export function trackRejectBid(workspace: { id: string; name?: string }, categoryName?: string): void {
  safeCallSegment(() => {
    analytics.track('Reject bid', {
      workspace,
      categoryName
    });
  });
}

// Delete bid from ws bid preveiw
export function trackDeleteBid(workspace: { id: string; name?: string }, categoryName?: string): void {
  safeCallSegment(() => {
    analytics.track('Delete bid', {
      workspace,
      categoryName
    });
  });
}

// Add manual bid (not calloff)
export function trackCreateManualBid(workspace: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('Create manual bid', {
      workspace
    });
  });
}

/** BT Document Create Task */
export function trackNavigateToDraft(bidId: string): void {
  safeCallSegment(() => {
    analytics.track('BT Document Create Task', {
      bidId
    });
  });
}

/** BT Document Select */
export function trackSelectFileDraft(props: { bidId: string; filePath: string }): void {
  safeCallSegment(() => {
    analytics.track('BT Document Select', props);
  });
}

/** BT Document Preview */
export function trackPreviewDraft(props: { bidId: string; groupId: string; taskId: string }): void {
  safeCallSegment(() => {
    analytics.track('BT Document Preview', props);
  });
}

/** BT Document Preview edit */
export function trackPreviewEditDraft(props: {
  bidId: string;
  groupId: string;
  taskId: string;
  mode: 'title' | 'question';
}): void {
  safeCallSegment(() => {
    analytics.track('BT Document Preview edit', props);
  });
}

/** BT Document switch groups */
export function trackSwitchDraft(props: { bidId: string; groupId?: string; filePath: string }): void {
  safeCallSegment(() => {
    analytics.track('BT Document switch groups', props);
  });
}

/** BT Document Add Task to Bid */
export function trackPublishDraft(bidId: string): void {
  safeCallSegment(() => {
    analytics.track('BT Document Add Task to Bid', {
      bidId
    });
  });
}

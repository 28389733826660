import { safeCallSegment } from '../helpers';

export type AllBidspacesFilter = 'Bidspace' | 'Status' | 'Assigned to' | 'Bid type';

export function trackABFilter(filter: AllBidspacesFilter): void {
  safeCallSegment(() => {
    analytics.track('AB filtering', {
      filter
    });
  });
}

export function trackABDashboard(): void {
  safeCallSegment(() => {
    analytics.track('AB dashboard');
  });
}

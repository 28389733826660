import { TasksFilterArgs, BidTasksGroup, GROUP_LIMIT_COUNT } from './types';

export function isTasksFilterApplied(filter?: TasksFilterArgs): boolean {
  return (
    !!filter?.fulfillment?.length ||
    !!filter?.responseType?.length ||
    !!filter?.status?.length ||
    !!filter?.requirementType?.length ||
    !!filter?.assignedTo?.length
  );
}

export function hitGroupLimits(existingGroups: BidTasksGroup[], checkedFiles: string[]): boolean {
  const manualGroups = existingGroups.filter(group => !group.isGenerated);
  return manualGroups.length + checkedFiles.length > GROUP_LIMIT_COUNT;
}

import { FieldPolicy } from '@apollo/client';
import { ITendersCached } from '../types';
import { isNotUndefined } from 'src/helpers';

export function getTendersPolicy(): FieldPolicy {
  return {
    keyArgs: ['query', ['sortOnField', 'matchingProfileId', 'isUnhandled', 'isStarred'], 'advanced'],
    read(existing: ITendersCached) {
      if (!existing) {
        return;
      }
      if (!!existing) {
        return existing;
      }
    },
    merge(existing: ITendersCached, incoming: ITendersCached, { args, readField, canRead, isReference }) {
      if (!existing) {
        return incoming;
      }
      if (incoming && !!incoming.procurementsWithScore && incoming.procurementsWithScore.length === 0) {
        return { ...existing, count: incoming.count };
      }
      if (incoming && incoming.procurementsWithScore.length) {
        let merged =
          existing && !!existing.procurementsWithScore.length
            ? [...existing.procurementsWithScore]
                .filter(tender => canRead(tender.procurement) && isReference(tender.procurement))
                .filter(isNotUndefined)
            : [];
        if (args) {
          const existingUniqueIds = new Set(
            merged
              .map(tender => canRead(tender.procurement) && readField({ fieldName: 'id', from: tender.procurement }))
              .filter(isNotUndefined)
          );
          const incomingUnique = incoming.procurementsWithScore.filter(
            tender => !existingUniqueIds.has(readField({ fieldName: 'id', from: tender.procurement }))
          );
          merged = [...merged, ...incomingUnique];
        }
        return {
          ...existing,
          procurementsWithScore: merged
        };
      }
    }
  };
}

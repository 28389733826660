import { SupplierSearchTerm } from 'src/models/matchingProfiles/types';
import { MappedTendersTypes, SupplierOrg, SupplierString, TendersFilterStatus, TendersVars } from './types';
import { ExpiringContractsTier, SearchOnlyStatus, SupplierSearchType } from '@tendium/prom-types';
import { MatchingProfile } from 'src/models/matchingProfiles';

export function isSearchApplied<T extends TendersVars>(filter: T, initFilter: T): boolean {
  const cpvCodesApplied = !!filter.cpvCodes && !!filter.cpvCodes.length;
  const nutCodesApplied = !!filter.nutsCodes && !!filter.nutsCodes.length;
  const deadlineApplied = !!filter.deadline;
  const publishedApplied = !!filter.published;
  const assignedToApplied = !!filter.assignedTo && !!filter.assignedTo.length;
  const statusApplied = filter.onlyStatus !== initFilter.onlyStatus;
  return (
    !!filter.search ||
    cpvCodesApplied ||
    nutCodesApplied ||
    deadlineApplied ||
    publishedApplied ||
    !!filter.name ||
    assignedToApplied ||
    statusApplied ||
    !!filter.awarded
  );
}

export function toSupplierStrings(terms?: SupplierSearchTerm[]): SupplierString[] | undefined {
  return terms
    ?.filter(term => term.searchType === SupplierSearchType.SearchStrings)
    .map(({ value, status }) => ({ orgName: value, status }));
}
export function toSupplierIds(terms?: SupplierSearchTerm[]): SupplierOrg[] | undefined {
  return terms
    ?.filter(term => term.searchType === SupplierSearchType.OrgIds)
    .map(({ value, status }) => ({ orgId: value, status }));
}

export function getNextOffset(length: number, total: number, pageSize: number): { offset: number } {
  return {
    offset: Math.min(Math.floor(length / pageSize) * pageSize, total)
  };
}

export function getQueryParamsFromTenderStatus(status?: TendersFilterStatus): {
  onlyStatus?: SearchOnlyStatus;
  awarded?: boolean;
} {
  const defaultParams = {
    onlyStatus: undefined,
    awarded: undefined
  };
  if (!status) return defaultParams;
  switch (status) {
    case TendersFilterStatus.all:
      return {
        onlyStatus: undefined,
        awarded: undefined
      };
    case TendersFilterStatus.awarded:
      return {
        onlyStatus: undefined,
        awarded: true
      };
    case TendersFilterStatus.expired:
      return {
        onlyStatus: SearchOnlyStatus.Expired,
        awarded: undefined
      };
    case TendersFilterStatus.recent:
      return {
        onlyStatus: SearchOnlyStatus.Recent,
        awarded: undefined
      };
    default:
      return defaultParams;
  }
}

export function checkExpiringContracts<T extends keyof MappedTendersTypes>(type: T): boolean {
  return type === 'ExpContract' || type === 'SupplierProfile';
}

export function toEcParams(
  type: keyof MappedTendersTypes,
  initVars: TendersVars,
  mp?: MatchingProfile,
  expiringContractsTier?: ExpiringContractsTier
): Partial<TendersVars> {
  const supplierOrgIds = toSupplierIds(mp?.supplierSearchTerms);
  const supplierStrings = toSupplierStrings(mp?.supplierSearchTerms);

  const commonParams = mp
    ? {
        profileId: mp.id,
        ...initVars,
        cpvCodes: !!mp?.cpvCodes?.length ? mp.cpvCodes : undefined,
        cpvCodesExact: !!mp?.cpvCodesExact?.length ? mp.cpvCodesExact : undefined,
        nutsCodes: !!mp?.nutsCodes?.length ? mp.nutsCodes : undefined,
        nutsCodesExact: !!mp?.nutsCodesExact?.length ? mp.nutsCodesExact : undefined,
        keywordWidth: mp?.keywordWidth,
        filterKeywordLanguages: mp?.filterKeywordLanguages,
        highlightKeywordLanguages: mp?.highlightKeywordLanguages,
        keywordGroups: mp?.apiKeywordGroups
          ? mp?.apiKeywordGroups?.map(group => ({
              values: group.values.map(keyword => keyword.value),
              searchType: group.searchType,
              searchLogic: group.searchLogic,
              filterLogic: group.filterLogic,
              highlightColor: group.highlightColor,
              groupId: group.groupId
            }))
          : undefined,
        keywordArgs:
          mp?.apiKeywords?.map(keyword => ({
            value: keyword.value,
            searchType: keyword.searchType,
            searchLogic: keyword.searchLogic,
            filterLogic: keyword.filterLogic,
            highlightColor: keyword.highlightColor
          })) ?? undefined,
        buyerOrgIds: !!mp?.buyerOrgIds?.length ? mp.buyerOrgIds : undefined,
        buyerSearchStrings: !!mp?.buyerSearchStrings?.length ? mp.buyerSearchStrings : undefined,
        procuringAgencyTypes: !!mp?.buyerTypes?.length ? mp.buyerTypes : undefined,
        supplierSearchStrings: !!supplierStrings?.length ? supplierStrings : undefined,
        supplierSearchLogic: mp?.supplierSearchLogic,
        contractValueRange:
          mp?.contractValueCurrency || mp?.contractValueMax || mp?.contractValueMin
            ? {
                currency: mp?.contractValueCurrency,
                max: mp?.contractValueMax,
                min: mp?.contractValueMin
              }
            : undefined,
        contractValueExcludeMissingValue: mp?.contractValueExcludeMissingValue
      }
    : {};

  switch (type) {
    case 'ExpContract':
      return mp
        ? {
            ...commonParams,
            supplierOrgIds: !!supplierOrgIds?.length ? supplierOrgIds : undefined,
            contractEnd: expiringContractsTier === ExpiringContractsTier.Light ? initVars?.contractEnd : mp?.contractEnd
          }
        : initVars;
    case 'SupplierProfile':
      // Prioritize filters on the page
      // - Should use supplierOrgIds, contractEnd from initVars
      return mp
        ? {
            ...commonParams
          }
        : initVars;
    default:
      return initVars;
  }
}

import React, { FC } from 'react';
import styles from './index.module.scss';
import { Tooltip, Button } from 'src/common';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck, faSquareXmark } from '@fortawesome/pro-light-svg-icons';

export interface IProps {
  onChange: () => void;
  onCancel: () => void;
}
export const ValueActions: FC<IProps> = ({ onCancel, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.actions}>
      <Tooltip title={t('Common.Blocks.pressEnter')}>
        <Button
          type={'text'}
          icon={<FontAwesomeIcon icon={faSquareCheck} className={styles.saveIcon} />}
          onClick={onChange}
        />
      </Tooltip>
      <Button
        type={'text'}
        icon={<FontAwesomeIcon icon={faSquareXmark} className={styles.deleteIcon} />}
        onClick={onCancel}
      />
    </div>
  );
};

export default ValueActions;

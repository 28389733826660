import React, { FC } from 'react';
import styles from './index.module.scss';
import Accordion from 'src/common/Accordion';
import { useTranslation } from 'react-i18next';
import { TenderList } from './TenderList';
import { MappedSupplier } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { SupplierLink } from 'src/shared';

interface Props {
  mappedSupplier: MappedSupplier;
  expandedId?: string;
  setExpandedId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const SupplierListItem: FC<Props> = ({ mappedSupplier, expandedId, setExpandedId }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.item} key={mappedSupplier.supplier?.organisationNumber}>
      <Accordion
        label={
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              <SupplierLink
                orgId={mappedSupplier.supplier?.organisationNumber}
                orgName={mappedSupplier.supplier?.organisationName ?? undefined}
              >
                {mappedSupplier.supplier?.organisationName}
              </SupplierLink>
            </div>
            <div className={styles.count}>
              <FontAwesomeIcon icon={faCircleSmall} size="xs" className={styles.icon} />
              {t('Tenders.Billing.contractCount', { count: mappedSupplier.tenders?.length })}
            </div>
          </div>
        }
        onToggleComplete={() =>
          setExpandedId(prev =>
            prev === mappedSupplier.supplier?.organisationNumber
              ? undefined
              : mappedSupplier.supplier?.organisationNumber
          )
        }
        value={expandedId === mappedSupplier.supplier?.organisationNumber}
        className={styles.accordionThin}
      >
        <TenderList tenders={mappedSupplier.tenders} />
      </Accordion>
    </div>
  );
};

export default SupplierListItem;

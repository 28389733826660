import { Paths } from 'src/pages/paths';
import { ALL_DASHES_REGEX } from 'src/helpers';
import { IApiOrganization, ORG_ID_BULK_MODE_REGEX } from './types';

export function getBuyerLink(orgId: string): string {
  return `${Paths.ANALYTICS_PAGE}${Paths.BUYER_PAGE.replace(':orgId', orgId.replace(ALL_DASHES_REGEX, ''))}`;
}

export function getSupplierLink(orgId: string, orgName?: string): string {
  const supplierUrl = `${Paths.ANALYTICS_PAGE}${Paths.SUPPLIER_PAGE.replace(
    ':orgId',
    orgId.replace(ALL_DASHES_REGEX, '')
  )}`;
  return !!orgName ? `${supplierUrl}?orgName=${orgName}` : supplierUrl;
}

export function isContainOrgId(orgId: string): boolean {
  const value = orgId.trimStart().replace(ALL_DASHES_REGEX, '');

  return ORG_ID_BULK_MODE_REGEX.test(value);
}

export function getSupplierNameByOrgId(supplierData: IApiOrganization[], supplierOrgId: string): string {
  return supplierData.find(d => d.organisationNumber === supplierOrgId)?.organisationName ?? supplierOrgId;
}

import React, { useState, ReactNode, PropsWithChildren, useMemo, useRef, useLayoutEffect, SyntheticEvent } from 'react';
import styles from './index.module.scss';
import { AxisUnit, BarAccessor, MIN_M_WIDTH, MIN_Q_WIDTH, StackAccessor, StackData, LEFT_SIDEBAR } from './types';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
import dayjs from 'src/helpers/dayjs';
import { useMeasure } from 'react-use';
import { StackSeries } from './StackSeries';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
interface IProps<T extends StackData> {
  data: T[];
  label: StackAccessor<T, ReactNode>;
  rowTooltip: StackAccessor<T, ReactNode>;
  barTooltip: BarAccessor<T, ReactNode>;
  onClickTitle: (id: string, data: T) => void;
  axises: AxisUnit[];
  isResponsive?: boolean;
  startDate?: number;
  endDate?: number;
  focusDate?: number;
  tooltipContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}
export const StackTimeline = <T extends StackData>(props: PropsWithChildren<IProps<T>>): JSX.Element => {
  const {
    axises: rawAxises,
    data: rawData,
    label,
    rowTooltip,
    barTooltip,
    onClickTitle,
    isResponsive,
    startDate,
    endDate,
    focusDate,
    tooltipContainerRef
  } = props;
  const [hoverTitleId, setHoverTitleId] = useState<string | null>(null);
  const [hoverBarId, setHoverBarId] = useState<string | null>(null);
  const currentSidebar = useCurrentSidebar();
  const [measureRef, { width: contentWidth }] = useMeasure();

  const parentWidth = useMemo(() => contentWidth - LEFT_SIDEBAR, [contentWidth]);
  const data = useMemo(
    () => new StackSeries<T>({ data: rawData, parentWidth, isResponsive, startDate, endDate, focusDate }),
    [rawData, parentWidth, isResponsive, startDate, endDate, focusDate]
  );

  const axises: AxisUnit[] = useMemo(
    () =>
      data.axises['month'][1] && data.axises['month'][1].range.to - data.axises['month'][1].range.from < MIN_M_WIDTH
        ? ['year', 'quarter']
        : rawAxises,
    [data.axises, rawAxises]
  );
  const bgTicks = useMemo(
    () => (axises.includes('year') ? data.axises['year'] : data.axises['quarter']),
    [axises, data.axises]
  );

  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (focusDate && parentWidth > 0) {
      setTimeout(() => {
        setScrollState(data.toInitialScrollLeft(parentWidth));
      }, 0);
    }
  }, [data, focusDate, parentWidth]);

  const headerRightRef = useRef<HTMLDivElement | null>(null);
  const rightBlockRef = useRef<HTMLDivElement | null>(null);

  const setScrollState = (value: number): void => {
    if (headerRightRef.current && rightBlockRef.current) {
      headerRightRef.current.scrollLeft = value;
      rightBlockRef.current.scrollLeft = value;
    }
  };

  const syncScrollState = (e: SyntheticEvent<EventTarget & HTMLDivElement>): void => {
    const targetScrollValue = e.currentTarget.scrollLeft;
    setScrollState(targetScrollValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={classNames(styles.right, styles.hideScroll)} ref={headerRightRef} onScroll={syncScrollState}>
          <div className={styles.rightInner}>
            <div className={classNames(styles.rightHeader, styles.axises)}>
              {rawAxises.map(axis => (
                <div className={styles.ticks} key={axis}>
                  {data.axises[axis].map(({ label, range }) => (
                    <span
                      className={styles.tick}
                      style={{ width: range.to - range.from }}
                      key={`${dayjs(range.from).valueOf()}-${label}`}
                    >
                      {axis === 'year'
                        ? dayjs(label).year()
                        : axis === 'quarter'
                        ? `Q${dayjs(label).quarter()} ${range.to - range.from > MIN_Q_WIDTH ? dayjs(label).year() : ''}`
                        : dayjs(label).format('MMM')}
                    </span>
                  ))}
                </div>
              ))}
              <ul className={styles.bgTicks}>
                {bgTicks.map(({ range }) => (
                  <li className={styles.bgTick} key={range.from} style={{ width: range.to - range.from }} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.dataContainer}
        ref={node => {
          if (node) {
            measureRef(node);
            ref.current = node;
          }
        }}
      >
        <div className={styles.left}>
          {data.stacks.map(stack => (
            <div className={styles.item} key={stack.id}>
              <div
                className={classNames(styles.label, {
                  [styles.isActive]: hoverBarId === stack.id || currentSidebar?.id === stack.id
                })}
                onClick={() => onClickTitle(stack.id, stack.data)}
                onMouseEnter={() => setHoverTitleId(stack.id)}
                onMouseLeave={() => setHoverTitleId(null)}
              >
                {label(stack)}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.right} ref={rightBlockRef} onScroll={syncScrollState}>
          <div className={styles.rightInner} style={{ width: data.width }}>
            {data.stacks.map(stack => (
              <div className={styles.item} key={stack.id} style={{ width: data.width }}>
                <Tooltip
                  title={rowTooltip(stack)}
                  visible={hoverTitleId === stack.id}
                  getPopupContainer={() => (tooltipContainerRef.current ? tooltipContainerRef.current : document.body)}
                  placement="topLeft"
                >
                  <span
                    className={classNames(styles.stackInner, {
                      [styles.isActive]: hoverTitleId === stack.id || currentSidebar?.id === stack.id
                    })}
                    style={{ left: stack.ranges[0]?.value.from }}
                    onClick={() => onClickTitle(stack.id, stack.data)}
                    onMouseEnter={() => setHoverBarId(stack.id)}
                    onMouseLeave={() => setHoverBarId(null)}
                  >
                    {stack.ranges.map((range, index) => (
                      <Tooltip
                        title={barTooltip(range, index, stack)}
                        key={range.label.from.valueOf()}
                        getPopupContainer={() =>
                          tooltipContainerRef.current ? tooltipContainerRef.current : document.body
                        }
                        placement="topRight"
                      >
                        <span
                          className={classNames(styles.stackBar, { [styles.origin]: index === 0 })}
                          style={{ width: range.value.to - range.value.from }}
                        />
                      </Tooltip>
                    ))}
                  </span>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
StackTimeline.displayName = 'StackTimeline';

export default StackTimeline;

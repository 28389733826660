import { scaleOrdinal } from '@visx/scale';
import { ScaleBand, ScaleOrdinal } from 'd3-scale';
import { CHART_COLOR_PALETTE, pieChartResponsiveness, pieChartSize, PieChartSize } from './types';

export function groupByDate<T>(data: T[], getDate: (d: T) => string): { date: string; data: T[] }[] {
  const map: { [key: string]: T[] } = {};

  data.forEach(d => {
    const date = getDate(d);
    if (!map[date]) {
      map[date] = [];
    }
    map[date].push(d);
  });

  return Object.entries(map).map(([date, data]) => ({ date, data }));
}

export function createColorScale(colorDomain?: string[], colorPalette?: string[]): ScaleOrdinal<string, string> {
  return scaleOrdinal<string, string>({
    domain: colorDomain ?? [],
    range: colorPalette ?? CHART_COLOR_PALETTE
  });
}

export function getX(value: string, xScale: ScaleBand<string>): number {
  return (xScale(value) ?? 0) + xScale.bandwidth() / 2;
}

export function getPieChartResponsiveSize(width: number): PieChartSize {
  // Use regular size when mobile size
  if (window.innerWidth <= 1024) {
    return pieChartSize.regular;
  }
  // Use large size when there is enough room
  if (width > pieChartResponsiveness.large) {
    return pieChartSize.large;
  }
  // Otherwise fallback to regular size regardless
  return pieChartSize.regular;
}

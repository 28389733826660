import React, { JSX, useContext, useState } from 'react';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { BAR_MIN_HEIGHT, BAR_OPACITY_BLUR, BAR_RADIUS, BaseChartProps, SPACE_BETWEEN_BARS } from '../types';
import { groupByDate } from '../helpers';
import { XYChartContext } from '../XYChart/context';

export function BarStackGroup<T>(props: BaseChartProps<T>): JSX.Element | null {
  const { data, getXValue, getLegendKey, getYValue, onMouseEnter, onMouseLeave, onClick } = props;
  const [focus, setFocus] = useState<string | null>(null);

  const context = useContext(XYChartContext);
  if (!context) return null;

  const { xScale, yScale, colorScale, margin } = context;

  const groupData = groupByDate(data, getXValue);

  return (
    <Group>
      {groupData.map((data, i) => {
        let cumulativeValue = 0;
        let yOffset = SPACE_BETWEEN_BARS;
        return (
          <Group key={`bar-stack-${i}`}>
            {data.data.map((d, j) => {
              const value = getYValue(d);
              const key = getLegendKey(d);
              const barWidth = xScale.bandwidth();
              const barHeightActual = yScale(0) - yScale(value);
              const barHeight = Math.max(barHeightActual, BAR_MIN_HEIGHT);

              yOffset += Math.max(barHeight - barHeightActual, 0);

              const barY = yScale(cumulativeValue + value) - yOffset;
              const barX = xScale(data.date) ?? 0;

              cumulativeValue += value;
              yOffset += SPACE_BETWEEN_BARS;

              return (
                <Bar
                  key={`bar-${i}-${j}`}
                  x={barX}
                  y={barY}
                  rx={BAR_RADIUS}
                  ry={BAR_RADIUS}
                  width={barWidth}
                  height={barHeight}
                  fill={colorScale(key)}
                  opacity={focus && focus !== key ? BAR_OPACITY_BLUR : '100%'}
                  onMouseLeave={() => {
                    setFocus(null);
                    onMouseLeave?.();
                  }}
                  onMouseEnter={() => {
                    setFocus(key);

                    onMouseEnter?.({
                      tooltipData: d,
                      tooltipTop: barY - margin.top + margin.bottom,
                      tooltipLeft: barX + margin.left + barWidth
                    });
                  }}
                  onClick={() => {
                    onClick?.(d);
                  }}
                />
              );
            })}
          </Group>
        );
      })}
    </Group>
  );
}

export default BarStackGroup;

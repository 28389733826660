import { gql } from '@apollo/client';
import { BASE_BOX_FIELDS, LOT_FIELDS, BOX_FIELDS_FIELD } from '../queries';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';
import { COMMENT_FIELDS } from 'src/models/comments/queries';

const BOX_PREVIEW_FIELDS = gql`
  fragment boxPreviewFields on TenderBox {
    ...baseBoxFields
    ...boxFieldsField
    lots {
      ...lotFields
    }
  }
  ${BASE_BOX_FIELDS}
  ${BOX_FIELDS_FIELD}
  ${LOT_FIELDS}
`;
export const TENDER_PREVIEW_FIELDS = gql`
  fragment tenderPreviewFields on Tender {
    id
    isRead
    procurementStatus
    isStarred
    assignedTo {
      ...assignedToFields
    }
    summaries @include(if: $includeSummary) {
      eng
      original
    }
    timeline {
      id
      availableDate {
        ...boxPreviewFields
      }
      contractStartAndEndStart {
        ...boxPreviewFields
      }
      contractStartAndEndEnd {
        ...boxPreviewFields
      }
      deadlineOfClarificationQuestions {
        ...boxPreviewFields
      }
      deadline {
        ...boxPreviewFields
      }
    }
    lots {
      ...lotFields
    }
    general {
      id
      name {
        ...boxPreviewFields
      }
      introduction {
        ...boxPreviewFields
      }
      description {
        ...boxPreviewFields
      }
      scopeOfContract {
        ...boxPreviewFields
      }
      buyerBoxes {
        ...boxPreviewFields
      }
      contractDurationBoxes {
        ...boxPreviewFields
      }
      locationBoxes {
        ...boxPreviewFields
      }
      placeOfPerformanceNutsCode {
        ...boxPreviewFields
      }
      linkToProcurerBoxes {
        ...boxPreviewFields
      }
      contractValueBoxes {
        ...boxPreviewFields
      }
      contractRenewalBoxes {
        ...boxPreviewFields
      }
      typeOfProcedureBoxes {
        ...boxPreviewFields
      }
      mainCpvCode {
        ...boxPreviewFields
      }
      additionalCpvCode {
        ...boxPreviewFields
      }
    }
    bids {
      id
      itemId
      workspace {
        id
      }
      status {
        id
      }
    }
    rejected(matchingProfileId: $matchingProfileId)
  }
  ${BOX_PREVIEW_FIELDS}
  ${LOT_FIELDS}
  ${ASSIGNED_TO_FIELDS}
`;
export const GET_TENDER_PREVIEW = gql`
  query getTenderPreview($procurementId: String!, $matchingProfileId: String, $includeSummary: Boolean!) {
    getTender(query: { id: $procurementId }) {
      ...tenderPreviewFields
    }
  }
  ${TENDER_PREVIEW_FIELDS}
`;

export const SIMPLE_TENDER_FIELDS = gql`
  fragment simpleTenderFields on OCDSTender {
    id
    isRead
    isStarred
    assignedTo {
      ...assignedToFields
    }
    comments {
      ...commentFields
    }
    bids {
      id
      itemId
      workspace {
        id
      }
    }
    date
    tender {
      title
      value {
        currency
        amount
      }
      tenderPeriod {
        endDate
      }
    }
    parties {
      name
      identifier {
        id
      }
      roles
    }
    procurementStatus
    rejected(matchingProfileId: $matchingProfileId)
  }
  ${COMMENT_FIELDS}
  ${ASSIGNED_TO_FIELDS}
`;

import React, { FC, useCallback, useRef, useState } from 'react';
import styles from './index.module.scss';
import { IBoxFieldUrl } from 'src/models/procurements/Tender/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import AddValueButton from '../AddValueButton';
import ReadOnlyBlockLink from './ReadOnlyBlock';
import { useTranslation } from 'react-i18next';
import { useClickAway, useKey } from 'react-use';
import EditableBlockLink from './EditableBlock';
import { IBlockProps, FieldViewMode } from 'src/shared/Tender/Boxes/types';

interface IProps extends IBlockProps<IBoxFieldUrl> {}
export const BlockLink: FC<IProps> = ({ field, onChange, viewMode, isEditable, translation, config }) => {
  const [currentMode, setCurrentMode] = useState(viewMode || FieldViewMode.Read);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const switchToReadMode = useCallback(() => {
    if (isEditable && currentMode === FieldViewMode.Edit) {
      setCurrentMode(FieldViewMode.Read);
    }
  }, [currentMode, isEditable]);

  const onToggleView = useCallback(() => {
    isEditable && setCurrentMode(prev => (prev === FieldViewMode.Read ? FieldViewMode.Edit : FieldViewMode.Read));
  }, [isEditable]);

  const onFinish = useCallback(
    (val: IBoxFieldUrl) => {
      onChange?.(val);
      switchToReadMode();
    },
    [onChange, switchToReadMode]
  );

  useKey('Escape', () => setCurrentMode(FieldViewMode.Read));

  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    switchToReadMode();
  });

  return (
    <>
      {currentMode === FieldViewMode.Edit && (
        <EditableBlockLink containerRef={ref} onChange={onFinish} field={field} onFinish={switchToReadMode} />
      )}

      {currentMode === FieldViewMode.Read && (
        <>
          {!!field.url ? (
            <div className={classNames(styles.toggler, { [styles.isEditable]: isEditable })}>
              <ReadOnlyBlockLink
                title={field.title}
                url={field.url}
                translation={translation}
                className={classNames(styles.text)}
                config={config}
              />
              {isEditable && (
                <button className={styles.togglerIcon} onClick={onToggleView}>
                  <FontAwesomeIcon icon={faPen} />
                </button>
              )}
            </div>
          ) : (
            <AddValueButton onToggleView={onToggleView} isEditable={!!isEditable} title={t('Common.Blocks.addLink')} />
          )}
        </>
      )}
    </>
  );
};

export default BlockLink;

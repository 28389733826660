import { gql } from '@apollo/client/core';
import { USER_NAME_FIELDS } from 'src/models/users/queries';
import { FILE_NODE_FIELDS } from 'src/models/documentsLibrary/fragments';
import { COMMENT_FIELDS } from '../../comments/queries';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';

export const DOCUMENT_COORDINATES_FIELDS = gql`
  fragment documentCoordinatesFields on DocumentPosition {
    page
    start {
      x
      y
    }
    end {
      x
      y
    }
    pageSize {
      width
      height
    }
  }
`;

const TASK_DOCUMENT_FIELDS = gql`
  fragment questionDocumentFields on Document {
    content
    headline
    path
    name
    contentPosition {
      ...documentCoordinatesFields
    }
    headlinePosition {
      ...documentCoordinatesFields
    }
  }
  ${DOCUMENT_COORDINATES_FIELDS}
`;

const TASK_GROUP_FIELDS = gql`
  fragment questionGroupFields on ProcurementBidQuestionGroup {
    id
    title
    bid {
      id
      itemId
      itemType
    }
    isGenerated
    filePath
  }
`;
export const TASK_ANSWER_FIELDS = gql`
  fragment taskAnswerFields on ProcurementBidQuestionAnswer {
    ... on YesNoAnswer {
      answer
    }
    ... on FreeTextAnswer {
      content
    }
    ... on FreeYesNoAnswer {
      answer
      content
    }
    ... on FileAnswer {
      files {
        ...fileNodeFields
      }
    }
    ... on MultipleAnswer {
      answer
      content
      files {
        ...fileNodeFields
      }
    }
  }
  ${FILE_NODE_FIELDS}
`;
export const TASK_FIELDS = gql`
  fragment questionFields on ProcurementBidQuestion {
    id
    title
    assignedTo {
      ...assignedToFields
    }
    question
    requirementType
    classificationId
    fulfilment
    responseType
    content
    status
    isSubmitted
    deadline
    questionGroup {
      ...questionGroupFields
    }
    document {
      ...questionDocumentFields
    }
    answer {
      ...taskAnswerFields
    }
    comments {
      ...commentFields
    }
  }
  ${TASK_ANSWER_FIELDS}
  ${USER_NAME_FIELDS}
  ${TASK_GROUP_FIELDS}
  ${COMMENT_FIELDS}
  ${TASK_DOCUMENT_FIELDS}
  ${ASSIGNED_TO_FIELDS}
`;

export const CREATE_TASK = gql`
  mutation createBidTask(
    $groupId: String!
    $title: String!
    $content: String!
    $requirementType: RequirementType
    $responseType: ResponseType
    $deadline: Float
    $question: String!
    $isSubmitted: Boolean
  ) {
    createBidQuestion(
      input: {
        questionGroupId: $groupId
        title: $title
        content: $content
        requirementType: $requirementType
        responseType: $responseType
        deadline: $deadline
        question: $question
        isSubmitted: $isSubmitted
      }
    ) {
      ...questionFields
    }
  }
  ${TASK_FIELDS}
`;

export const UPDATE_TASK = gql`
  mutation updateBidTask(
    $questionIds: [String!]!
    $groupId: String!
    $bidId: String
    $title: String
    $content: String
    $requirementType: RequirementType
    $fulfilment: TaskFulfillment
    $responseType: ResponseType
    $assignedTo: String
    $status: QuestionStatus
    $deadline: Float
  ) {
    updateBidQuestions(
      input: {
        questionIds: $questionIds
        questionGroupId: $groupId
        bidId: $bidId
        title: $title
        content: $content
        requirementType: $requirementType
        fulfilment: $fulfilment
        responseType: $responseType
        assignedTo: $assignedTo
        status: $status
        deadline: $deadline
      }
    ) {
      ...questionFields
    }
  }
  ${TASK_FIELDS}
`;
export const DELETE_TASK = gql`
  mutation deleteBidQuestions($questionIds: [String!]!, $groupId: String!) {
    deleteBidQuestions(input: { questionIds: $questionIds, questionGroupId: $groupId })
  }
`;

export const SET_TASK_ANSWER = gql`
  mutation setBidQuestionAnswer($questionId: String!, $questionGroupId: String!, $answer: AnswerInput) {
    setBidQuestionAnswer(input: { questionId: $questionId, questionGroupId: $questionGroupId, answer: $answer })
  }
`;

export const DELETE_TASK_ANSWER = gql`
  mutation setBidQuestionAnswer($questionId: String!, $questionGroupId: String!) {
    setBidQuestionAnswer(input: { questionId: $questionId, questionGroupId: $questionGroupId, answer: null })
  }
`;

export const GET_TASK = gql`
  query getTask($taskId: String!) {
    getBidQuestion(input: { questionId: $taskId }) {
      ...questionFields
    }
  }
  ${TASK_FIELDS}
`;

export const GET_TASK_GROUP = gql`
  query getTaskGroup($taskId: String!) {
    getBidQuestion(input: { questionId: $taskId }) {
      id
      questionGroup {
        id
        title
        filePath
        isGenerated
        bid {
          id
        }
      }
    }
  }
`;

import React, { FC, Ref, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { IApiBoxField, isFieldString, ITenderBox } from 'src/models/procurements/Tender/types';
import classNames from 'classnames';
import { useUpdateBox } from 'src/models/bids/BidPreview/hooks';
import useCurrentTenderId from 'src/reactiveVars/CurrentTenderIdVar';
import useCurrentBidId from 'src/reactiveVars/CurrentBidIdVar';
import OrgsSearch from 'src/shared/OrgsSearch';
import { IApiOrganization, useOrganisationById } from 'src/models/organisations';
import { notUndefined } from 'src/helpers/typescript';
import { updateCacheAddTenderBox, useAddTenderBox } from 'src/models/procurements/Tender/hooks';
import { BoxFieldString } from 'src/models/procurements';
import { MultiBuyerBoxesProps } from '../type';
import { SchemaUtils } from '@tendium/prom-types';
const { BuyerBoxFieldNames } = SchemaUtils.TenderBox;

export interface Props extends MultiBuyerBoxesProps {
  containerRef: Ref<HTMLDivElement>;
  buyerBox?: ITenderBox;
  onFinish?: () => void;
}
export const EditableBuyer: FC<Props> = ({ containerRef, buyerBox, className, onFinish }) => {
  const buyerNameField = useMemo(() => {
    const name = buyerBox?.fields.find(field => field.name === BuyerBoxFieldNames.NAME);
    return isFieldString(name) ? name : new BoxFieldString(BuyerBoxFieldNames.NAME);
  }, [buyerBox]);
  const buyerOrgIdField = useMemo(() => {
    const orgId = buyerBox?.fields.find(field => field.name === BuyerBoxFieldNames.ORG_ID);
    return isFieldString(orgId) ? orgId : new BoxFieldString(BuyerBoxFieldNames.ORG_ID);
  }, [buyerBox]);
  const { data: currentOrg } = useOrganisationById(buyerOrgIdField.string ?? undefined);

  const [addTenderBox] = useAddTenderBox();
  const [updateTenderBox] = useUpdateBox();
  const [tenderId] = useCurrentTenderId();
  const [bidId] = useCurrentBidId();

  const onBuyerChange = useCallback(
    (exact?: string, prefix?: string, org?: IApiOrganization) => {
      if (!tenderId || !bidId || !buyerBox) return;
      const { keyName, title, category, specificationId, rawFields, id } = buyerBox;
      const updatedFields = (
        org
          ? [buyerNameField.update(org.organisationName), buyerOrgIdField.update(org.organisationNumber)]
          : prefix
          ? [buyerNameField.update(prefix), buyerOrgIdField.update(null)]
          : [buyerNameField.update(null), buyerOrgIdField.update(null)]
      ).filter(notUndefined);
      const fields: IApiBoxField[] = (
        rawFields.length === 2
          ? updatedFields
          : updatedFields.map(updField => rawFields.find(origField => origField.id === updField.id) || updField)
      ).map(field => field.apiField);
      if (keyName) {
        addTenderBox({
          variables: {
            id: tenderId,
            bidId,
            box: {
              title,
              category,
              specificationId,
              fields
            }
          },
          update: updateCacheAddTenderBox(tenderId, buyerBox.keyName)
        });
      } else {
        updateTenderBox(tenderId, bidId, {
          specificationId,
          title,
          id,
          category,
          fields
        });
      }
      (prefix || org) && onFinish?.();
    },
    [tenderId, bidId, buyerBox, buyerNameField, buyerOrgIdField, onFinish, addTenderBox, updateTenderBox]
  );

  return (
    <div ref={containerRef} className={classNames(styles.container, styles.fields, styles.linkFields, className)}>
      <div className={styles.field}>
        <OrgsSearch
          onSelect={onBuyerChange}
          mode={'single'}
          exact={buyerOrgIdField.string ?? undefined}
          prefix={currentOrg ? undefined : buyerNameField.string ?? undefined}
        />
      </div>
    </div>
  );
};

export default EditableBuyer;

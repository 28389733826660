import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import styles from './index.module.scss';

interface IProps {
  href: string;
  className?: string;
  title: string;
}

export const LearnMoreLink: FC<IProps> = props => {
  const { className, href, title } = props;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={classNames(styles.link, className)}>
      <FontAwesomeIcon icon={faBookOpen} />
      <span>{title}</span>
    </a>
  );
};

export default LearnMoreLink;

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Heading, List, Paging, PagingProps } from 'src/common';
import { IApiOrganization } from 'src/models/organisations';
import { useOverlappingTenders } from 'src/models/procurements/Billing/hooks';
import { OCDSTenderObject } from 'src/models/procurements/Tender/types';
import { SupplierListItem } from './SupplierListItem';
import styles from './index.module.scss';
import { ApiTenderTransactionsInput } from 'src/models/procurements/Billing/types';
import { BILLING_SUPPLIERS_PAGE_SIZE } from '../types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export interface Props {
  vars?: ApiTenderTransactionsInput;
  suppliers: IApiOrganization[];
  tenderId?: string;
  className?: string;
  loading?: boolean;
}

export type MappedSupplier = {
  supplier?: IApiOrganization;
  tenders: IdentifiableTenderObject[];
};

export type IdentifiableTenderObject = {
  id: string;
  tender: OCDSTenderObject;
};

export const SupplierList: FC<Props> = ({ vars, tenderId, suppliers, className, loading }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedId, setExpandedId] = useState<string | undefined>();

  const onChange: PagingProps['onChange'] = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const { data, loading: tendersLoading } = useOverlappingTenders(
    vars?.supplierOrgIds ?? [],
    vars?.buyerOrgIds[0],
    tenderId
  );

  const isLoading = useMemo(() => !!loading || tendersLoading, [loading, tendersLoading]);

  const mappedSuppliers = useMemo(() => {
    const supplierMap: { [key: string]: IdentifiableTenderObject[] } = {};
    data?.tenders.forEach(({ id, tender, parties }) => {
      parties.forEach(party => {
        if (party.roles?.find(role => role === 'Supplier') && party.identifier?.id) {
          if (!supplierMap[party.identifier?.id]) {
            supplierMap[party.identifier?.id] = [];
          }
          if (!supplierMap[party.identifier?.id].some(d => id === d.id) && id !== tenderId) {
            supplierMap[party.identifier?.id].push({ id, tender });
          }
        }
      });
    });

    return Object.entries(supplierMap).reduce<{ supplier: IApiOrganization; tenders: IdentifiableTenderObject[] }[]>(
      (acc, [supplierId, tenders]) => {
        const supplier = suppliers.find(s => s.organisationNumber === supplierId);
        if (supplier && tenders.length > 0) {
          acc.push({ supplier, tenders });
        }
        return acc;
      },
      []
    );
  }, [data?.tenders, suppliers, tenderId]);

  const currentSuppliers = useMemo(
    () =>
      mappedSuppliers.slice(BILLING_SUPPLIERS_PAGE_SIZE * (currentPage - 1), BILLING_SUPPLIERS_PAGE_SIZE * currentPage),
    [currentPage, mappedSuppliers]
  );

  const totalPages = currentSuppliers.length;

  return (
    <>
      {(!!mappedSuppliers.length || isLoading) && (
        <div className={classNames(styles.container, className)}>
          <div>
            <Heading title={t('Tenders.Billing.multipleContracts')} />
            <List items={currentSuppliers} loading={isLoading}>
              {(item: MappedSupplier) => (
                <SupplierListItem mappedSupplier={item} expandedId={expandedId} setExpandedId={setExpandedId} />
              )}
            </List>
          </div>
          <Paging
            defaultCurrent={1}
            current={currentPage}
            onChange={onChange}
            pageSize={BILLING_SUPPLIERS_PAGE_SIZE}
            total={totalPages}
            className={styles.paging}
          />
        </div>
      )}
    </>
  );
};

export default SupplierList;

import { ApiAssignedTo } from 'src/models/users/AssignedTo/types';
import { ApiBidTasksGroup, BidQuestionType } from '../BidFull/types';
import { IFileNode } from 'src/models/documentsLibrary/types';
import { IApiComment } from 'src/models/comments/types';
import { BidTask } from '.';

export enum RequirementType {
  Must = 'Must',
  Should = 'Should',
  Info = 'Info',
  Contract = 'Contract',
  NA = 'NA'
}
export enum ResponseType {
  YesNo = 'YesNo',
  FreeYesNo = 'FreeYesNo',
  File = 'File',
  FreeText = 'FreeText',
  Multiple = 'Multiple',
  NA = 'NA'
}
export enum QuestionStatus {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  Review = 'Review',
  Done = 'Done'
}
export enum TaskFulfillment {
  Fulfill = 'Fulfill',
  Unsure = 'Unsure',
  NotFulfill = 'NotFulfill',
  NA = 'NA'
}

export interface ApiBidTask {
  id: string;
  title: string | null;
  content: string | null;
  question: string | null;
  status: QuestionStatus;
  requirementType: RequirementType | null;
  fulfilment: TaskFulfillment | null;
  responseType: ResponseType | null;
  assignedTo: ApiAssignedTo | null;
  deadline: number | null;
  isSubmitted: boolean | null;
  answer: IAnswer | null;
  questionGroup: ApiBidTasksGroup;
  comments: IApiComment[];
  isGenerated: boolean;
  document: IBidDocument | null;
  classificationId?: string;
}
export interface IYesNoAnswer {
  answer: boolean | null;
}
export interface IFreeTextAnswer {
  content: string | null;
}
export interface IFileAnswer {
  files: IFileNode[] | null;
}
export interface IMultipleAnswer extends IYesNoAnswer, IFreeTextAnswer, IFileAnswer {}

export type IAnswer = IMultipleAnswer;

export interface IBidDocument {
  path: string;
  content: string;
  headline: string;
  contentPosition: IBidDocumentContent[];
  headlinePosition: IBidDocumentContent[];
}

export interface IBidDocumentContent {
  page: number;
  start: {
    x: number;
    y: number;
  };
  end: {
    x: number;
    y: number;
  };
  pageSize: {
    width: number;
    height: number;
  };
}

export interface CreateBidTaskVars extends CreateBidTaskValues {
  groupId: string;
}
export interface CreateBidTaskValues {
  question: string;
  title: string;
  content: string;
  requirementType?: RequirementType;
  responseType?: ResponseType;
  deadline?: number;
  isSubmitted?: boolean;
}
export const DEFAULT_TASK_PARAMS: CreateBidTaskValues = {
  title: '',
  content: '',
  question: ''
};
export type BidTaskValues = Partial<
  Pick<ApiBidTask, 'fulfilment' | 'title' | 'content' | 'requirementType' | 'responseType' | 'status' | 'deadline'>
> & { assignedTo?: string | null };
export type BidTaskInput = BidTaskValues & {
  questionIds: string[];
  groupId: string;
  bidId?: string;
};
export type BidTaskFnInput = Omit<BidTaskInput, 'questionIds'> & {
  questions: BidTask[];
};

export interface ApiTaskResponse {
  getBidQuestion: ApiBidTask;
}
export type ApiTaskVars = { taskId: string };
export type ApiTaskNav = Pick<ApiBidTask, 'id'> & {
  questionGroup: Pick<ApiBidTasksGroup, 'id' | 'title' | 'filePath' | 'isGenerated'> & { bid: { id: string } };
};
export type TaskNavData = {
  groupId: string;
  bidId: string;
  activeTaskId: string;
  groupName: string;
  type: BidQuestionType;
};

export function isTaskDone(task: BidTask): boolean {
  return task.status === QuestionStatus.Done;
}

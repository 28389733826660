import { gql } from '@apollo/client';
import { BOX_FIELDS_FIELD } from '../queries';

const TRANSACTION_FIELDS = gql`
  fragment transactionFields on PeriodTransaction {
    buyerOrgId
    supplierOrgId
    amount
    cumulativeValue
    currency
    period
  }
`;

const CUMULATIVE_TRANSACTION_FIELDS = gql`
  fragment cumulativeTransactionFields on CumulativeTransaction {
    from
    to
    value
    currency
  }
`;

const BUYER_COVERAGE_GAP_FIELDS = gql`
  fragment buyerCoverageGapFields on BuyerCoverageGap {
    period
  }
`;

export const GET_TRANSACTIONS_PERIOD = gql`
  query getTransactionsPeriod(
    $buyerOrgIds: [String!]!
    $supplierOrgIds: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $periodType: String
  ) {
    getTransactionsPeriod(
      buyerOrgIds: $buyerOrgIds
      supplierOrgIds: $supplierOrgIds
      dateFrom: $dateFrom
      dateTo: $dateTo
      periodType: $periodType
    ) {
      currency
      transactions {
        ...transactionFields
      }
    }
  }
  ${TRANSACTION_FIELDS}
`;

export const GET_TRANSACTIONS_PERIOD_AND_CUMULATIVE = gql`
  query getTransactionsPeriodAndCumulative(
    $buyerOrgIds: [String!]!
    $supplierOrgIds: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $contractStart: String
    $contractEnd: String
    $extensionEndDates: [String!]
    $periodType: String
    $displaySupplierCount: Float
  ) {
    getTransactionsPeriod(
      buyerOrgIds: $buyerOrgIds
      supplierOrgIds: $supplierOrgIds
      dateFrom: $dateFrom
      dateTo: $dateTo
      contractStart: $contractStart
      contractEnd: $contractEnd
      extensionEndDates: $extensionEndDates
      periodType: $periodType
      displaySupplierCount: $displaySupplierCount
    ) {
      currency
      organisations {
        organisationName
        organisationNumber: givenNumber
      }
      transactions {
        ...transactionFields
      }
      cumulativeTransactions {
        ...cumulativeTransactionFields
      }
      buyerCoverageGaps {
        ...buyerCoverageGapFields
      }
    }
  }
  ${TRANSACTION_FIELDS}
  ${CUMULATIVE_TRANSACTION_FIELDS}
  ${BUYER_COVERAGE_GAP_FIELDS}
`;

export const GET_TENDER_CONTRACT_INFO = gql`
  query getTenderContractInfo($id: String!) {
    getTender(query: { id: $id }) {
      general {
        buyerBoxes {
          ...boxFieldsField
        }
      }
      timeline {
        availableDate {
          ...boxFieldsField
        }
        deadline {
          ...boxFieldsField
        }
      }
      expiringContract {
        contractEnd
        contractStart
        extensionEndDates
      }
    }
  }
  ${BOX_FIELDS_FIELD}
`;

export const GET_SIMPLE_OVERLAPPING_TENDERS = gql`
  query getSimpleTenders(
    $advanced: AdvancedSearchDTO
    $sortOnField: SortOnFieldInputObject
    $amount: Float
    $offset: Float
    $matchingProfileId: String
    $isStarred: Boolean
    $isUnhandled: Boolean
  ) {
    getSimpleTenders(
      advanced: $advanced
      query: {
        amount: $amount
        offset: $offset
        sortOnField: $sortOnField
        matchingProfileId: $matchingProfileId
        isStarred: $isStarred
        isUnhandled: $isUnhandled
      }
    ) {
      procurementsWithScore {
        procurement {
          id
          tender {
            title
            value {
              currency
              amount
            }
            tenderPeriod {
              endDate
            }
            contractPeriod {
              endDate
              startDate
            }
          }
          parties {
            identifier {
              id
            }
            name
            roles
          }
        }
      }
    }
  }
`;

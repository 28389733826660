import { MutationTuple } from '@apollo/client/react/types/types';
import { ApolloError, MutationUpdaterFn, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_TASK,
  DELETE_TASK,
  DELETE_TASK_ANSWER,
  GET_TASK,
  GET_TASK_GROUP,
  SET_TASK_ANSWER,
  UPDATE_TASK
} from './queries';
import { useCallback, useContext, useMemo } from 'react';
import { notification } from 'src/common';
import { useTranslation } from 'react-i18next';
import { BidQuestionType, TasksCachedResult, TasksFilterArgs } from '../BidFull/types';
import {
  ApiBidTask,
  ApiTaskNav,
  ApiTaskResponse,
  ApiTaskVars,
  BidTaskFnInput,
  BidTaskInput,
  CreateBidTaskValues,
  CreateBidTaskVars,
  DEFAULT_TASK_PARAMS,
  QuestionStatus,
  TaskFulfillment
} from './types';
import { TaskTrackCreateData, TaskTrackPickerData, trackTaskCreate, trackBTDeleteTask } from 'src/segment/events';
import { useLocation, useParams } from 'react-router-dom';
import {
  TaskContext,
  TaskContextValue,
  TaskFormContexValue,
  TaskFormContext,
  TaskNavContext,
  TasksNavCtx
} from './context';
import { BidTask } from '.';
import { Paths } from 'src/pages/paths';
import { useTasksFilter } from '../BidFull/hooks';
import { isTasksFilterApplied } from '../BidFull/helpers';

type CreateBidTaskData = {
  createBidQuestion: ApiBidTask;
};
export type CreateBidTaskInput = Pick<CreateBidTaskVars, 'groupId'> &
  Pick<TaskTrackCreateData, 'eventSource' | 'mode' | 'bidId'> & {
    onComplete?: (taskId?: string) => void;
    task?: CreateBidTaskValues;
    isSubmitted?: boolean;
  };
export function useCreateBidTask(): [(data: CreateBidTaskInput) => void, { loading: boolean; error?: ApolloError }] {
  const { t } = useTranslation();
  const [createTask, { loading, error }] = useMutation<CreateBidTaskData, CreateBidTaskVars>(CREATE_TASK);

  const createTaskFn = useCallback(
    (data: CreateBidTaskInput) => {
      const { groupId, bidId, eventSource, mode, onComplete, task: inputTask, isSubmitted } = data;
      const task = inputTask ?? DEFAULT_TASK_PARAMS;
      const { question, title, ...restVars } = task;

      createTask({
        variables: { title: !!question ? question : title, question, groupId, isSubmitted, ...restVars },
        update: updateCacheOnCreateBidTask(groupId, bidId, isSubmitted)
      })
        .then(({ data }) => {
          const taskId = data?.createBidQuestion.id;
          taskId && trackTaskCreate({ groupId, bidId, taskId, eventSource, mode });
          onComplete?.(taskId);
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createTask, t]
  );
  return useMemo(() => [createTaskFn, { loading, error }], [createTaskFn, error, loading]);
}
function updateCacheOnCreateBidTask(
  groupId: string,
  bidId?: string,
  isSubmitted?: boolean
): MutationUpdaterFn<CreateBidTaskData> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const newTaskId = data.createBidQuestion.id;
    cache.modify({
      fields: {
        getGroupQuestions(cached: TasksCachedResult, { toReference }) {
          if (
            cached.variables?.groupId === groupId &&
            cached.variables.type === BidQuestionType.Manual &&
            cached.variables.isSubmitted === isSubmitted
          ) {
            return {
              ...cached,
              value: {
                ...cached.value,
                totalCount: cached.value.totalCount + 1,
                questions: [
                  ...cached.value.questions,
                  toReference({ __typename: 'ProcurementBidQuestion', id: newTaskId })
                ]
              }
            };
          } else {
            return cached;
          }
        }
      }
    });

    cache.modify({
      id: cache.identify({
        __typename: 'QuestionGroupStatistics',
        groupId
      }),
      fields: {
        totalTasks(count: number) {
          return count + 1;
        },
        unAssigned(count: number) {
          return count + 1;
        },
        todo(count: number) {
          return count + 1;
        }
      }
    });
  };
}
interface IUpdateBidQuestionsResponse {
  __typename: 'Mutation';
  updateBidQuestions: boolean;
}

export function useUpdateBidTasks(): [(input: BidTaskFnInput) => void, { loading: boolean; error?: ApolloError }] {
  const { t } = useTranslation();
  const { filter: filters } = useTasksFilter();
  const [updateTasks, { loading, error }] = useMutation<IUpdateBidQuestionsResponse, BidTaskInput>(UPDATE_TASK);
  const updateTasksFn = useCallback(
    (input: BidTaskFnInput) => {
      const { questions, ...variables } = input;
      updateTasks({
        variables: {
          ...variables,
          questionIds: questions.map(item => item.id)
        },
        update: updateCacheOnUpdateTasks(input, isTasksFilterApplied(filters) ? filters : undefined)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [filters, t, updateTasks]
  );
  return useMemo(() => [updateTasksFn, { loading, error }], [updateTasksFn, error, loading]);
}

type TaskFilters = Omit<TasksFilterArgs, 'fulfillment'> & { fulfilment: TaskFulfillment[] };
export function updateCacheOnUpdateTasks(
  input: BidTaskFnInput,
  filters?: TasksFilterArgs
): MutationUpdaterFn<IUpdateBidQuestionsResponse> {
  const { questions, groupId, bidId, ...values } = input;
  const valueMatchFilter = (filterField: keyof TasksFilterArgs, value: unknown): boolean | undefined => {
    if (!filters || filters[filterField] === undefined) return true;
    return filters[filterField]?.some(i => i === value);
  };

  return (cache, { data }) => {
    if (!data?.updateBidQuestions) {
      return;
    }

    for (const fieldToUpdate in values) {
      if (fieldToUpdate !== undefined) {
        const field = fieldToUpdate as keyof TasksFilterArgs;
        questions.forEach(question => {
          const id = cache.identify({
            __typename: 'ProcurementBidQuestion',
            id: question.id
          });

          // If current item should not be present in the list anymore
          const itemWillDisappear =
            valueMatchFilter(
              field,
              field === 'assignedTo' ? question.assignedTo : question[field as keyof TaskFilters]
            ) && !valueMatchFilter(field, values[field as keyof TaskFilters]);

          if (filters) {
            cache.modify({
              fields: {
                getGroupQuestions(cached: TasksCachedResult) {
                  const filterValue =
                    cached.variables !== undefined &&
                    cached.variables.filters !== undefined &&
                    cached.variables.groupId === groupId
                      ? cached.variables.filters[field]
                      : undefined;
                  const currentValue =
                    field === 'assignedTo' ? question['assignedTo'] : question[field as keyof TaskFilters];

                  if (
                    filterValue &&
                    filterValue.some(value => value === currentValue) &&
                    filterValue.every(value => value !== values[field as keyof TaskFilters])
                  ) {
                    const cachedQuestions = cached.value.questions.filter(question => question.__ref !== id);

                    return {
                      ...cached,
                      value: {
                        ...cached.value,
                        questions: cachedQuestions,
                        totalCount: cachedQuestions.length
                      }
                    };
                  } else {
                    return cached;
                  }
                }
              }
            });
          }

          if (field === 'assignedTo') {
            cache.modify({
              id,
              fields: {
                assignedTo() {
                  return values[field]
                    ? {
                        __ref: cache.identify({
                          __typename: 'AssignedTo',
                          id: values[field]
                        })
                      }
                    : null;
                }
              }
            });

            const newStatusValue = (status: QuestionStatus) => {
              return (count: number) => {
                return valueMatchFilter(field, question['assignedTo']) &&
                  !valueMatchFilter(field, values[field]) &&
                  question.status === status
                  ? count - 1
                  : count;
              };
            };
            cache.modify({
              id: cache.identify({
                __typename: 'QuestionGroupStatistics',
                groupId
              }),
              fields: {
                todo: newStatusValue(QuestionStatus.ToDo),
                inProgress: newStatusValue(QuestionStatus.InProgress),
                review: newStatusValue(QuestionStatus.Review),
                done: newStatusValue(QuestionStatus.Done),
                unAssigned(count: number) {
                  if (valueMatchFilter(field, values[field as keyof TaskFilters])) {
                    return values[field] === null
                      ? count + 1
                      : values[field] && !question['assignedTo']
                      ? count - 1
                      : count;
                  }

                  if (itemWillDisappear && !question['assignedTo']) {
                    return count - 1;
                  }

                  return count;
                },
                totalTasks(count: number) {
                  return itemWillDisappear ? count - 1 : count;
                }
              }
            });
          } else {
            cache.modify({
              id,
              fields: {
                [field]() {
                  return values[field as keyof typeof values];
                }
              }
            });

            if (field === 'status' || filters) {
              const newStatusValue = (status: QuestionStatus) => {
                return (count: number) => {
                  if (field === 'status') {
                    if (valueMatchFilter(field, values[field as keyof TaskFilters])) {
                      return values['status'] === status ? count + 1 : question.status === status ? count - 1 : count;
                    }

                    if (itemWillDisappear && question.status === status) {
                      return count - 1;
                    }

                    return count;
                  }

                  return itemWillDisappear && question.status === status ? count - 1 : count;
                };
              };
              cache.modify({
                id: cache.identify({
                  __typename: 'QuestionGroupStatistics',
                  groupId
                }),
                fields: {
                  todo: newStatusValue(QuestionStatus.ToDo),
                  inProgress: newStatusValue(QuestionStatus.InProgress),
                  review: newStatusValue(QuestionStatus.Review),
                  done: newStatusValue(QuestionStatus.Done),
                  totalTasks(count: number) {
                    return itemWillDisappear ? count - 1 : count;
                  },
                  unAssigned(count: number) {
                    return itemWillDisappear && question['assignedTo'] === null ? count - 1 : count;
                  }
                }
              });
            }
          }
        });
      }
    }
  };
}
type DeleteBidTasksVars = {
  questionIds: string[];
  groupId: string;
};
type DeleteBidTasksData = {
  deleteBidQuestions: boolean;
};
type DeleteBidTasksInput = DeleteBidTasksVars &
  Omit<TaskTrackPickerData, 'taskProp' | 'groupId'> & {
    classificationId?: string;
    isSubmitted?: boolean;
  };
export function useDeleteBidTasks(): [(input: DeleteBidTasksInput) => void, { loading: boolean; error?: ApolloError }] {
  const { t } = useTranslation();
  const { filter: filters } = useTasksFilter();
  /** @todo move out to layouts, it's not necessarily bidId in params on different pages, eg on 'Task Page' */
  const { id: bidId } = useParams<{ id: string }>();
  /** @todo end */
  const [deleteTasks, { loading, error }] = useMutation<DeleteBidTasksData, DeleteBidTasksVars>(DELETE_TASK);
  const deleteTasksFn = useCallback(
    (input: DeleteBidTasksInput) => {
      const { questionIds, groupId, taskType, mode, eventSource, isSubmitted, classificationId } = input;
      trackBTDeleteTask({ mode, groupId, bidId, taskType, eventSource });
      deleteTasks({
        variables: { questionIds, groupId },
        update: updateCacheOnDeleteBidTasks(
          { groupId, questionIds, mode, isSubmitted, classificationId },
          isTasksFilterApplied(filters) ? filters : undefined
        )
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [bidId, deleteTasks, t, filters]
  );
  return useMemo(() => [deleteTasksFn, { loading, error }], [deleteTasksFn, error, loading]);
}
export function updateCacheOnDeleteBidTasks(
  input: Omit<DeleteBidTasksInput, 'type'>,
  filters?: TasksFilterArgs
): MutationUpdaterFn<DeleteBidTasksData> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    const { groupId, questionIds, mode, classificationId, isSubmitted } = input;
    const modifyGroupStatistics = (questionId: string): void => {
      cache.modify({
        id: cache.identify({
          __typename: 'QuestionGroupStatistics',
          groupId
        }),
        fields: {
          totalTasks(count: number) {
            return Math.max(0, count - 1);
          },
          unAssigned(count: number, { readField, toReference }) {
            const taskRef = toReference({ __typename: 'ProcurementBidQuestion', id: questionId });
            return readField('assignedTo', taskRef) ? count : count - 1;
          },
          todo(count: number, { readField, toReference }) {
            const taskRef = toReference({ __typename: 'ProcurementBidQuestion', id: questionId });
            const taskStatus = readField('status', taskRef);
            return taskStatus === QuestionStatus.ToDo ? count - 1 : count;
          },
          inProgress(count: number, { readField, toReference }) {
            const taskRef = toReference({ __typename: 'ProcurementBidQuestion', id: questionId });
            const taskStatus = readField('status', taskRef);
            return taskStatus === QuestionStatus.InProgress ? count - 1 : count;
          },
          review(count: number, { readField, toReference }) {
            const taskRef = toReference({ __typename: 'ProcurementBidQuestion', id: questionId });
            const taskStatus = readField('status', taskRef);
            return taskStatus === QuestionStatus.Review ? count - 1 : count;
          },
          done(count: number, { readField, toReference }) {
            const taskRef = toReference({ __typename: 'ProcurementBidQuestion', id: questionId });
            const taskStatus = readField('status', taskRef);
            return taskStatus === QuestionStatus.Done ? count - 1 : count;
          }
        }
      });
    };

    if (classificationId) {
      cache.modify({
        id: cache.identify({ classificationId, __typename: 'DocumentCoordinates' }),
        fields: {
          createdTask() {
            return false;
          }
        }
      });
    }
    if (mode === 'bulk') {
      questionIds.forEach(questionId => modifyGroupStatistics(questionId));

      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'getGroupQuestions',
        args: { input: { groupId, type: BidQuestionType.Generated, filters } }
      });
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'getGroupQuestions',
        args: { input: { groupId, type: BidQuestionType.Manual, filters } }
      });
      cache.gc();
      return;
    }

    questionIds.forEach(questionId => {
      cache.modify({
        fields: {
          getGroupQuestions(cached: TasksCachedResult, { readField }) {
            if (
              cached.variables?.groupId === groupId &&
              cached.variables.isSubmitted === isSubmitted &&
              cached.value.questions.some(task => readField('id', task) === questionId)
            ) {
              return {
                ...cached,
                value: {
                  ...cached.value,
                  totalCount: Math.max(cached.value.totalCount - 1, 0),
                  questions: [...cached.value.questions].filter(task => readField('id', task) !== questionId)
                }
              };
            } else {
              return cached;
            }
          }
        }
      });
      modifyGroupStatistics(questionId);
      cache.evict({ id: cache.identify({ id: questionId, __typename: 'ProcurementBidQuestion' }) });
    });
    cache.gc();
  };
}

export interface ISetBidQuestionAnswerOutput {
  setBidQuestionAnswer: boolean;
}
export interface ISetBidQuestionAnswerInput {
  questionId: string;
  questionGroupId: string;
  answer: ISetBidQuestionAnswer | null;
}
export interface ISetBidQuestionAnswer {
  answer?: boolean | null;
  content?: string | null;
  files?: string[] | null;
}
export interface IDeleteBidQuestionAnswerInput extends Omit<ISetBidQuestionAnswerInput, 'answer'> {}

export function useSetBidQuestionAnswer(): [
  (input: ISetBidQuestionAnswerInput, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [setAnswer, { loading, error }] = useMutation<ISetBidQuestionAnswerOutput, ISetBidQuestionAnswerInput>(
    SET_TASK_ANSWER
  );
  const setAnswerFn = useCallback(
    (input: ISetBidQuestionAnswerInput, onFinish?: () => void) => {
      setAnswer({
        variables: input,
        update: updateCacheOnSetBidQuestionAnswer(input)
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [t, setAnswer]
  );
  return useMemo(() => [setAnswerFn, { loading, error }], [setAnswerFn, error, loading]);
}

export function updateCacheOnSetBidQuestionAnswer(
  input: ISetBidQuestionAnswerInput
): MutationUpdaterFn<ISetBidQuestionAnswerOutput> {
  const { questionId, answer } = input;
  return (cache, { data }) => {
    if (!data?.setBidQuestionAnswer) {
      return;
    }

    const BidQuestion = cache.identify({
      __typename: 'ProcurementBidQuestion',
      id: questionId
    });
    cache.modify({
      id: BidQuestion,
      fields: {
        answer(cached, { readField, toReference }) {
          const responseType = readField('responseType');

          if (!responseType) {
            return null;
          }
          if (responseType === 'File' || 'Multiple') {
            const fileRefs = answer?.files?.map(fileId => {
              const id = cache.identify({
                __typename: 'FileNode',
                id: fileId
              });

              return id ? toReference(id) : null;
            });
            return cached
              ? { ...cached, ...answer, files: fileRefs, __typename: `${responseType}Answer` }
              : { ...answer, files: fileRefs, __typename: `${responseType}Answer` };
          }
          return cached
            ? { ...cached, ...answer, __typename: `${responseType}Answer` }
            : { ...answer, __typename: `${responseType}Answer` };
        }
      }
    });
  };
}

export function useDeleteBidQuestionAnswer(): MutationTuple<
  ISetBidQuestionAnswerOutput,
  IDeleteBidQuestionAnswerInput
> {
  return useMutation<ISetBidQuestionAnswerOutput, IDeleteBidQuestionAnswerInput>(DELETE_TASK_ANSWER);
}
export function generateTaskUrl(taskId?: string): string | undefined {
  return taskId ? `${Paths.TASK_ROUTE}${Paths.TASK_PAGE.replace(':id', taskId)}` : undefined;
}
export interface BidTaskResponse {
  data: BidTask | null;
  loading: boolean;
}

export function useTaskNavCtx(): TasksNavCtx {
  const { pathname } = useLocation();
  const [, , taskId] = pathname.split('/');

  const { data: navData, loading } = useQuery<{ getBidQuestion: ApiTaskNav }, ApiTaskVars>(GET_TASK_GROUP, {
    variables: { taskId: taskId },
    skip: !taskId
  });
  const groupId = navData?.getBidQuestion.questionGroup.id ?? '';

  return useMemo(
    () => ({
      data: navData
        ? {
            groupId,
            bidId: navData.getBidQuestion.questionGroup.bid.id,
            groupName: navData.getBidQuestion.questionGroup.title,
            type:
              navData.getBidQuestion.questionGroup.filePath && navData.getBidQuestion.questionGroup.isGenerated
                ? BidQuestionType.Generated
                : BidQuestionType.Manual,
            activeTaskId: taskId
          }
        : null,
      loading
    }),
    [groupId, loading, navData, taskId]
  );
}

export function useTaskNav(): TasksNavCtx {
  return useContext(TaskNavContext);
}

export function useLoadTask(): BidTaskResponse {
  const { pathname } = useLocation();
  const [, , taskId] = pathname.split('/');

  const query = useQuery<ApiTaskResponse, ApiTaskVars>(GET_TASK, {
    variables: { taskId: taskId ?? '' },
    skip: !taskId
  });

  return useMemo(
    () => ({
      data: query.data?.getBidQuestion ? new BidTask(query.data.getBidQuestion) : null,
      loading: query.loading
    }),
    [query]
  );
}

export function useTask(): TaskContextValue {
  return useContext(TaskContext);
}

export function useTaskForm(): TaskFormContexValue {
  return useContext(TaskFormContext);
}

import dayjs from 'src/helpers/dayjs';
import { checkRangesOverlapping, formatDate, getDateType, PeriodType, toDayJs } from 'src/helpers/dates';
import { groupByDate } from 'src/common/Charts/helpers';
import { CONTRACT_DURATION_YEARS_FALLBACK, PeriodTransaction, TenderContractInfo } from './types';
import { formatCurrencyValue } from '../Tender/helpers';
import isNumber from 'src/helpers/isNumber';
import { Language } from '@tendium/prom-types';

export function getCumulativeValueByDate(data: PeriodTransaction[]): { [date: string]: number } {
  const groupData = groupByDate(data, d => d.period);
  let result = {};
  groupData.forEach(d => {
    let sum = 0;
    d.data.forEach(tr => {
      sum += tr.amount;
    });
    result = { ...result, [d.date]: sum };
  });
  return result;
}

export function getRatioOfTotalReceived(amount: number, total: number): number {
  if (total === 0) return 0;
  return parseFloat(((amount / total) * 100).toFixed(0));
}

/**
 * Calculate dateFrom, dateTo and periodType for query 'getTransactionsPeriod'
 * -----------------------
 * Handle contract start date
 * - Use contract start date (contractStart)
 * - Use deadline (timeline.deadline) if no contract start date
 * - Use publication date (timeline.availableDate) if no deadline
 * - Use today if none of them exist (should not be possible)
 * Handle contract end date
 * - Use contract end date with all extensions
 * - Use 4 years after contract start date if missing end date
 * Visualization && Buffer Time
 * - Up to one year: Visualize time in months; Buffer: 3 months before and 3 months after. 18 months (new longest graph)
 * - Between 1 year and 4 years: Visualize time in quarters; Buffer: 1 quarter before and 1 quarter after. 18 months (new longest graph)
 * - Over 4 years: Visualize time in years; Buffer: 1 year before and 1 year after
 */
export function calcContractPeriodInput(dates: TenderContractInfo): {
  dateFrom: string;
  dateTo: string;
  periodType: PeriodType;
} {
  const { start, end } = toContractDatesFallback(dates);
  let startDate = toDayJs(start);
  let endDate = toDayJs(end);
  const diffByYear = dayjs.duration(endDate.diff(startDate)).asYears();
  let periodType: PeriodType = 'quarter';

  if (diffByYear < 1) {
    periodType = 'month';
  }

  if (diffByYear > 4) {
    periodType = 'year';
  }

  switch (periodType) {
    case 'month':
      startDate = startDate.subtract(3, 'M');
      endDate = endDate.add(3, 'M');
      break;
    case 'year':
      startDate = startDate.subtract(1, 'y');
      endDate = endDate.add(1, 'y');
      break;
    case 'quarter':
    default:
      startDate = startDate.subtract(1, 'Q');
      endDate = endDate.add(1, 'Q');
  }

  return {
    dateFrom: formatDate(startDate),
    dateTo: formatDate(endDate),
    periodType
  };
}

export function toCurrencyValue(
  language: Language,
  value?: number | null,
  currency?: string,
  allowEmpty?: boolean
): string {
  if (allowEmpty && !isNumber(value)) {
    return '—';
  }
  return [formatCurrencyValue(value ?? 0, language, currency ?? 'SEK')].join(' ');
}

export function getIndexFromContractPeriod(
  period: string,
  contractStart?: string,
  contractEnd?: string,
  extensionEndDates?: string[]
): number[] | undefined {
  if (!contractStart || !contractEnd) {
    return undefined;
  }
  const dates = [contractStart, contractEnd, ...(extensionEndDates ?? [])];
  const periodToCheck = toDayJs(period);
  const periodType = (getDateType(period) ?? 'month') as dayjs.ManipulateType;
  const rangeInPeriod: [dayjs.Dayjs, dayjs.Dayjs] = [
    periodToCheck.startOf(periodType),
    periodToCheck.endOf(periodType)
  ];
  const result: number[] = [];
  dates.forEach((d, i) => {
    if (i < dates.length - 1 && checkRangesOverlapping(rangeInPeriod, [d, dates[i + 1]])) {
      result.push(i);
    }
  });
  return result;
}

export function toLatestPossibleContractEndDate(
  contractEnd?: number,
  extensionEndDates?: number[]
): number | undefined {
  const allEndDates = [...(contractEnd ? [contractEnd] : []), ...(extensionEndDates ?? [])];
  if (allEndDates.length === 0) {
    return undefined;
  }
  return Math.max(...allEndDates);
}

export function toContractDatesFallback(dates: TenderContractInfo): { start: number; end: number } {
  const { contractStart, contractEnd, extensionEndDates, availableDate, deadline } = dates;
  const start = contractStart ?? deadline ?? availableDate ?? dayjs.utc().valueOf();
  let end = toLatestPossibleContractEndDate(contractEnd, extensionEndDates);
  if (!end) {
    end = toDayJs(start).add(CONTRACT_DURATION_YEARS_FALLBACK, 'y').valueOf();
  }
  return {
    start,
    end
  };
}

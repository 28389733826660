import React, { FC, useCallback, useMemo, memo } from 'react';
import { Button, Form, Input } from 'src/common';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import { IBlockEditProps } from 'src/shared/Tender/Boxes/types';
import styles from './index.module.scss';
const { TextArea } = Input;

interface IFormData {
  string: string;
}

export interface IProps extends IBlockEditProps<IBoxFieldString> {
  autoFocus?: boolean;
  autoSize?: boolean | AutoSizeType;
}
export const EditableBlockText: FC<IProps> = ({
  onChange,
  onFinish,
  field,
  containerRef,
  className,
  autoFocus,
  autoSize
}) => {
  const { id: fieldId, string } = field;
  const { t } = useTranslation();

  const [form] = useForm();

  const onSubmit = useCallback(() => {
    const value: IFormData = form.getFieldValue(fieldId);

    value.string !== field.string
      ? onChange && onChange(field.update(value.string !== '' ? value.string : null))
      : onFinish && onFinish();
  }, [field, fieldId, form, onChange, onFinish]);

  const onCancel = useCallback(() => {
    form.resetFields();
    onFinish && onFinish();
  }, [form, onFinish]);

  const initialValues = useMemo(() => {
    return {
      [fieldId]: {
        string
      }
    };
  }, [fieldId, string]);

  return (
    <div ref={containerRef} className={className}>
      <Form form={form} initialValues={initialValues} className={styles.container}>
        <div className={styles.inner}>
          <Form.Item name={[fieldId, 'string']} className={styles.textarea}>
            <TextArea
              autoSize={autoSize || true}
              placeholder={t('Common.Blocks.String')}
              autoFocus={autoFocus}
              onFocus={e => autoFocus && e.currentTarget.select()}
            />
          </Form.Item>
          <Form.Item className={styles.buttonsContainer}>
            <Button type={'text'} onClick={onCancel}>
              {t('Common.cancel')}
            </Button>
            <Button type={'primary'} onClick={() => onSubmit()}>
              {t('Common.save')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default memo(EditableBlockText);

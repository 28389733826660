import { useCallback, useMemo } from 'react';
import { ApolloCache, MutationUpdaterFn, Reference } from '@apollo/client/core';
import {
  LazyQueryExecFunction,
  LazyQueryResultTuple,
  QueryResult,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription
} from '@apollo/client';
import {
  COMMENT_COUNT_FIELDS,
  CREATE_COMMENT,
  DELETE_COMMENT,
  GET_COMMENT,
  GET_COMMENTS_COUNT,
  GET_PARENT_COMMENTS,
  MARK_COMMENT_AS_READ,
  ON_NEW_COMMENT
} from './queries';
import {
  ApiCommentsCountData,
  ApiCommentsCountVars,
  ApiParentCommentsData,
  ApiParentCommentsVars,
  CommentParentType,
  CommentsCount,
  CommentsCountCached,
  IApiComment,
  ICreateCommentResponse,
  ICreateCommentVariables,
  IUpdateCommentResponse,
  ParentCommentsCached
} from './types';
import { v4 } from 'uuid';
import { notification, useMe } from 'src/common';
import { useTranslation } from 'react-i18next';
import dayjs from 'src/helpers/dayjs';
import { trackAddComment, TrackEventSource } from 'src/segment/events';
import { MentionData } from 'src/common/Editor/plugins/Mentions/types';

interface ICreateCommentFnArgs {
  content: string;
  parentId: string;
  mentions: MentionData[];
  previousMentions: string[];
  parentType: CommentParentType;
  onCreate: (mentions: string[]) => void;
  shareWith?: string;
  eventSource: TrackEventSource;
}

export function useCreateComment(): [
  (args: ICreateCommentFnArgs) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const { data: user } = useMe();

  const [createComment, { loading, error }] = useMutation<ICreateCommentResponse, ICreateCommentVariables>(
    CREATE_COMMENT
  );
  const [markCommentAsRead] = useMarkCommentAsRead();
  const createCommentFn = useCallback(
    (args: ICreateCommentFnArgs) => {
      const {
        content,
        parentId,
        mentions: mentionsData,
        previousMentions,
        parentType,
        onCreate,
        shareWith,
        eventSource
      } = args;
      const mentions = mentionsData.map(mention => mention.id);
      if (user) {
        const newCommentId = v4();
        onCreate(mentions);
        createComment({
          variables: {
            id: newCommentId,
            content,
            parentId,
            mentions,
            previousMentions,
            shareWith: shareWith ? [shareWith] : [],
            parentType
          },
          update: getUpdateCacheOnAddComment(parentId, parentType),
          optimisticResponse: {
            __typename: 'Mutation',
            createProcurementComment: {
              id: newCommentId,
              parentId,
              parentType,
              content,
              sharedWith: !!shareWith ? [{ email: shareWith }] : [],
              isRead: true,
              createdAt: dayjs().toDate(),
              __typename: 'ProcurementComment',
              user: {
                __typename: 'User',
                ...user
              }
            }
          }
        })
          .then(({ data }) => {
            if (data) {
              trackAddComment({
                boxId: parentId,
                mentions: mentionsData,
                eventSource
              });
              markCommentAsRead(data.createProcurementComment.id);
            }
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
      }
    },
    [createComment, markCommentAsRead, t, user]
  );
  return [createCommentFn, { loading, error }];
}

type UpdateCacheOnAddCommentArgs = {
  parentId: string;
  parentType: CommentParentType;
  commentId: string;
  cache: ApolloCache<unknown>;
  updateIsRead?: boolean;
};
function updateCacheOnAddComment({
  parentId,
  parentType,
  commentId,
  updateIsRead,
  cache
}: UpdateCacheOnAddCommentArgs): void {
  let parentRef: string | undefined;
  if (
    parentType === CommentParentType.CallOffBid ||
    parentType === CommentParentType.ProcurementBid ||
    parentType === CommentParentType.ManualBid
  ) {
    parentRef = cache.identify({
      __typename: 'BidV2',
      id: parentId
    });
  } else if (parentType === CommentParentType.Procurement) {
    parentRef = cache.identify({
      __typename: CommentParentType.Tender,
      id: parentId
    });
  } else if (parentType === CommentParentType.ProcurementBox) {
    parentRef = cache.identify({
      __typename: CommentParentType.TenderBox,
      id: parentId
    });
  } else {
    parentRef = cache.identify({
      __typename: parentType,
      id: parentId
    });
  }
  updateIsRead &&
    cache.modify({
      id: cache.identify({ __typename: 'ProcurementComment', id: commentId }),
      fields: {
        isRead() {
          return true;
        }
      }
    });

  parentRef &&
    cache.modify({
      id: parentRef,
      fields: {
        comments(existingChildrenRefs: Reference[] = [], { readField, toReference }) {
          return existingChildrenRefs.some(ref => readField('id', ref) === commentId)
            ? existingChildrenRefs
            : [...existingChildrenRefs, toReference({ __typename: 'ProcurementComment', id: commentId })];
        }
      }
    });
  if (parentType === CommentParentType.Procurement) {
    cache.modify({
      fields: {
        getCommentsCount(cached: CommentsCountCached, { toReference, readField }) {
          if (cached.variables?.parentType !== CommentParentType.Procurement) return cached;
          if (cached.value.find(count => readField({ fieldName: 'parentId', from: count }) === parentId)) {
            cache.modify({
              id: cache.identify({ __typename: 'GetCommentsCountResult', parentId }),
              fields: {
                count(value: number) {
                  return value + 1;
                }
              }
            });
            return cached;
          } else {
            cache.writeFragment({
              fragment: COMMENT_COUNT_FIELDS,
              fragmentName: 'commentCountFields',
              data: {
                parentId,
                count: 1,
                __typename: 'GetCommentsCountResult'
              }
            });
            return {
              ...cached,
              value: [
                ...cached.value,
                toReference({
                  __typename: 'GetCommentsCountResult',
                  parentId
                })
              ]
            };
          }
        }
      }
    });
    cache.modify({
      fields: {
        getComments(cached: ParentCommentsCached, { toReference }) {
          if (cached.variables?.parentId !== parentId && cached.variables?.parentType !== CommentParentType.Procurement)
            return cached;
          return {
            ...cached,
            value: [
              ...cached.value,
              toReference({
                __typename: 'ProcurementComment',
                id: commentId
              })
            ]
          };
        }
      }
    });
  }
}
function getUpdateCacheOnAddComment(
  parentId: string,
  parentType: CommentParentType
): MutationUpdaterFn<ICreateCommentResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const commentId = data.createProcurementComment.id;
    updateCacheOnAddComment({ cache, parentId, parentType, commentId, updateIsRead: true });
  };
}

export function useDeleteComment(): [
  (commentId: string, parentId: string, parentType: CommentParentType) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [deleteComment, { loading, error }] = useMutation<
    { deleteProcurementComment: boolean; __typename: 'Mutation' },
    { id: string }
  >(DELETE_COMMENT);
  const deleteCommentFn = useCallback(
    (commentId: string, parentId: string, parentType: CommentParentType) => {
      deleteComment({
        variables: { id: commentId },
        update: getUpdateCacheOnDeleteComment(commentId, parentId, parentType),
        optimisticResponse: {
          __typename: 'Mutation',
          deleteProcurementComment: true
        }
      }).catch(() => {
        notification.error({
          message: t('Common.unknownError'),
          description: t('Common.unknownErrorDesc')
        });
      });
    },
    [deleteComment, t]
  );
  return [deleteCommentFn, { loading, error }];
}

export function getUpdateCacheOnDeleteComment(
  commentId: string,
  parentId: string,
  parentType: CommentParentType
): MutationUpdaterFn {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    let parentRef: string | undefined;
    if (
      parentType === CommentParentType.CallOffBid ||
      parentType === CommentParentType.ProcurementBid ||
      parentType === CommentParentType.ManualBid
    ) {
      parentRef = cache.identify({
        __typename: 'BidV2',
        id: parentId
      });
    } else if (parentType === CommentParentType.Procurement) {
      parentRef = cache.identify({
        __typename: CommentParentType.Tender,
        id: parentId
      });
    } else if (parentType === CommentParentType.ProcurementBox) {
      parentRef = cache.identify({
        __typename: CommentParentType.TenderBox,
        id: parentId
      });
    } else {
      parentRef = cache.identify({
        __typename: parentType,
        id: parentId
      });
    }

    parentRef &&
      cache.modify({
        id: parentRef,
        fields: {
          comments(existingChildrenRefs: Reference[], { readField }) {
            return existingChildrenRefs.filter(childrenRef => commentId !== readField('id', childrenRef));
          }
        }
      });
    if (parentType === CommentParentType.Procurement) {
      const commentCountRef = cache.identify({ __typename: 'GetCommentsCountResult', parentId });
      if (commentCountRef) {
        cache.modify({
          id: commentCountRef,
          fields: {
            count(value: number) {
              if (value > 2) {
                return value - 1;
              } else {
                cache.modify({
                  fields: {
                    getCommentsCount(cached: CommentsCountCached, { readField }) {
                      if (cached.variables?.parentType !== CommentParentType.Procurement) return cached;
                      return {
                        ...cached,
                        value: cached.value.filter(ref => readField({ fieldName: 'parentId', from: ref }) !== parentId)
                      };
                    }
                  }
                });
                cache.evict({
                  id: cache.identify({
                    __typename: 'GetCommentsCountResult',
                    parentId
                  })
                });
              }
            }
          }
        });
      }
      cache.modify({
        fields: {
          getComments(cached: ParentCommentsCached, { readField }) {
            if (
              cached.variables?.parentId !== parentId &&
              cached.variables?.parentType !== CommentParentType.Procurement
            )
              return cached;
            return {
              ...cached,
              value: cached.value.filter(ref => readField({ fieldName: 'id', from: ref }) !== parentId)
            };
          }
        }
      });
    }
    cache.evict({
      id: cache.identify({
        __typename: 'ProcurementComment',
        id: commentId
      })
    });
    cache.gc();
  };
}

export function useMarkCommentAsRead(): [(commentId: string) => void, { loading: boolean; error: Error | undefined }] {
  const { t } = useTranslation();
  const [markCommentAsRead, { loading, error }] = useMutation<IUpdateCommentResponse, { commentId: string }>(
    MARK_COMMENT_AS_READ
  );
  const markCommentAsReadFn = useCallback(
    (commentId: string) => {
      markCommentAsRead({
        variables: { commentId },
        update: getUpdateCacheOnMarkCommentAsRead(commentId)
      }).catch(() => {
        notification.error({
          message: t('Common.unknownError'),
          description: t('Common.unknownErrorDesc')
        });
      });
    },
    [markCommentAsRead, t]
  );
  return [markCommentAsReadFn, { loading, error }];
}

export function getUpdateCacheOnMarkCommentAsRead(commentId: string): MutationUpdaterFn {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const commentRef = cache.identify({
      __typename: 'ProcurementComment',
      id: commentId
    });

    cache.modify({
      id: commentRef,
      fields: {
        isRead() {
          return true;
        }
      }
    });
  };
}

export function useGetCommentById(): LazyQueryResultTuple<{ getComment: IApiComment | null }, { commentId: string }> {
  return useLazyQuery<{ getComment: IApiComment | null }, { commentId: string }>(GET_COMMENT);
}
export function useNewCommentSubscriptions(skip: boolean): void {
  const [getCommentById] = useGetCommentById();
  useSubscription<{ commentAdded: string }>(ON_NEW_COMMENT, {
    onData: ({ client, data }) => {
      if (!data) {
        return;
      }
      const newCommentId = data.data?.commentAdded;
      if (!newCommentId) {
        return;
      }
      getCommentById({ variables: { commentId: newCommentId } }).then(({ data }) => {
        if (!data?.getComment) return;
        const parentType = data.getComment.parentType;
        const parentId = data.getComment.parentId;
        const { cache } = client;
        updateCacheOnAddComment({ cache, parentId, parentType, commentId: newCommentId });
      });
    },
    skip
  });
}

export function useCommentsCount(): [
  LazyQueryExecFunction<ApiCommentsCountData, ApiCommentsCountVars>,
  Omit<QueryResult<ApiCommentsCountData, ApiCommentsCountVars>, 'data'> & {
    data?: CommentsCount[];
  }
] {
  const [lazyFn, lazyData] = useLazyQuery<ApiCommentsCountData, ApiCommentsCountVars>(GET_COMMENTS_COUNT, {
    fetchPolicy: 'cache-and-network'
  });
  return useMemo(() => [lazyFn, { ...lazyData, data: lazyData.data?.getCommentsCount }], [lazyData, lazyFn]);
}
export function useParentComments(variables?: ApiParentCommentsVars): [
  LazyQueryExecFunction<ApiParentCommentsData, ApiParentCommentsVars>,
  Omit<QueryResult<ApiParentCommentsData, ApiParentCommentsVars>, 'data'> & {
    data?: IApiComment[];
  }
] {
  const query = useQuery<ApiParentCommentsData, ApiParentCommentsVars>(GET_PARENT_COMMENTS, {
    fetchPolicy: 'cache-first',
    variables,
    skip: !variables
  });
  const [lazyFn] = useLazyQuery<ApiParentCommentsData, ApiParentCommentsVars>(GET_PARENT_COMMENTS, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first'
  });
  return useMemo(() => [lazyFn, { ...query, data: query.data?.getComments }], [lazyFn, query]);
}

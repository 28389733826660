import React, { FC } from 'react';
import { List } from 'src/common';
import { TenderListItem } from './TenderListItem';
import { IdentifiableTenderObject } from '../..';

interface Props {
  tenders: IdentifiableTenderObject[];
}

export const TenderList: FC<Props> = ({ tenders }) => {
  return <List items={tenders}>{tenderObject => tenderObject && <TenderListItem tenderObject={tenderObject} />}</List>;
};

import { IApiUser } from 'src/models/users/types';
import { IApiWorkspaceStatus } from 'src/models/workspace/WorkspaceStatus/types';
import { IApiComment } from 'src/models/comments/types';
import { BidItemType } from '@tendium/prom-types/tender';
import { ApiBidTask, QuestionStatus, RequirementType, ResponseType, TaskFulfillment } from '../BidTask/types';
import { IApiWorkspace } from 'src/models/workspace/types';
import { TasksData, BiddingStat } from '.';
import { QueryResult, Reference } from '@apollo/client';
import { ApiAssignedTo } from 'src/models/users/AssignedTo/types';
import { WebhookStatus } from 'src/models/company/Webhooks/types';

export interface IBiddingResponse {
  readonly getBid: IApiBidding;
}

export interface IApiBidding {
  id: string;
  bidStageId: string;
  createdAt: number;
  createdBy: IApiUser | null;
  comments: IApiComment[];
  assignedTo: ApiAssignedTo | null;
  status: IApiWorkspaceStatus | null;
  workspace: IApiWorkspace;
  itemType: BidItemType;
  itemId: string;
  questionGroups: ApiBidTasksGroup[];
}
export interface BiddingStatsResponse {
  readonly getQuestionGroupsStatistics: ApiBiddingStats[];
}
export interface ApiBiddingStats {
  groupId: string;
  totalTasks: number;
  unAssigned: number;
  todo: number;
  inProgress: number;
  review: number;
  done: number;
  __typename: string;
}
export type BiddingStats = Omit<ApiBiddingStats, '__typename' | 'groupId'>;
export type BiddingStatistic = { stats: [keyof BiddingStats, BiddingStat][]; total: number };
export type BiddingStatsCached = {
  value: Reference[];
  variables: { bidId: string; isSubmitted?: boolean; filters?: TasksFilterArgs };
};
export interface IApiBid {
  id: string;
  createdAt: number;
  createdBy: IApiUser | null;
  comments: IApiComment[];
  assignedTo: ApiAssignedTo | null;
  workspace: {
    id: string;
  };
  itemId: string;
  bidStageId: string | null;
  questionGroups: ApiBidTasksGroup[];
  webhookStatus?: WebhookStatus;
}

export interface TasksFilterArgs {
  fulfillment?: TaskFulfillment[];
  requirementType?: RequirementType[];
  responseType?: ResponseType[];
  status?: QuestionStatus[];
  assignedTo?: string[];
}
export interface ApiBidTasksGroup extends Omit<BidTasksGroup, 'bid' | 'questions'> {
  bid: {
    id: string;
    itemId: string;
    itemType: BidItemType;
  };
  questions: ApiBidTask[];
}
export interface BidTasksGroup {
  id: string;
  title: string;
  bid: {
    id: string;
  };
  color?: string;
  filePath?: string;
  isGenerated?: string;
  totalTasks?: number;
  totalDoneTasks?: number;
}
export enum ActiveBiddingTab {
  Answer = 'answer',
  Comments = 'comments'
}
export type TasksCached = {
  questions: Reference[];
  totalCount: number;
};
export type TasksCachedResult = {
  value: TasksCached;
  variables?: ApiTasksVars;
};
export const GROUP_TASKS_LIMIT = 15;
export enum BidQuestionType {
  Manual = 'Manual',
  Generated = 'Generated'
}
export interface TasksPagingArgs {
  offset: number;
  limit: number;
}
export interface ApiTasksArgs {
  groupId: string;
  type: BidQuestionType;
  isSubmitted?: boolean;
  pagination: TasksPagingArgs;
  filters?: TasksFilterArgs;
}
export interface ApiTasksVars extends Omit<ApiTasksArgs, 'pagination'>, TasksPagingArgs {}
export interface ApiTasksResponseData {
  totalCount: number;
  questions: ApiBidTask[];
}
export interface ApiTasksResponse {
  getGroupQuestions: ApiTasksResponseData;
}
export interface TasksResponseData extends Omit<QueryResult<ApiTasksResponse, ApiTasksVars>, 'data'> {
  data?: TasksData;
  fetchingMore: boolean;
}
export const GROUP_LIMIT_COUNT = 50;
export type UseLoadTasks = Pick<ApiTasksVars, 'groupId' | 'type' | 'isSubmitted'> &
  Partial<Pick<ApiTasksVars, 'limit'>> & { skip?: boolean };

import { ContentLibraryEventSource } from './../../models/contentLibrary/ContentLibrary/types';
import { safeCallSegment } from '../helpers';
import { ResponseType } from 'src/models/bids/BidTask/types';
import { ContentLibraryType } from '@tendium/prom-types/schema';

export enum ContentLibraryTrackFilter {
  assignedTo = 'Assigned to',
  createdBy = 'Created by',
  room = 'Room',
  tags = 'Tags',
  type = 'Type'
}

export function trackSwitchRoomCL(room: { id: string; name: string }): void {
  safeCallSegment(() => {
    analytics.track('CL switch rooms', {
      room
    });
  });
}

export function trackEditCL(): void {
  safeCallSegment(() => {
    analytics.track('CL Edit Content Library');
  });
}

export function trackAddRoomCL(room: { id: string; name: string }): void {
  safeCallSegment(() => {
    analytics.track('CL add room', {
      room
    });
  });
}

export function trackAddTagGroupCL(tagGroup: { id: string; name: string }): void {
  safeCallSegment(() => {
    analytics.track('CL add tag group', {
      tagGroup
    });
  });
}

export function trackAddTagCL(tag: { tagGroupId: string; tagName: string }): void {
  safeCallSegment(() => {
    analytics.track('CL add tag', {
      tag
    });
  });
}

export function trackFreeTextSearchCL(room: { id?: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('CL search field', {
      room
    });
  });
}

export function trackFilteringCL(eventSource: ContentLibraryEventSource, filter: ContentLibraryTrackFilter): void {
  safeCallSegment(() => {
    analytics.track('CL filtering', {
      eventSource,
      filter
    });
  });
}

export function trackSortByCL(eventSource: ContentLibraryEventSource, filter: string): void {
  safeCallSegment(() => {
    analytics.track('CL sorting', {
      eventSource,
      filter
    });
  });
}

export function trackAddContentCardCL(eventSource: ContentLibraryEventSource, fields?: string[]): void {
  safeCallSegment(() => {
    analytics.track('CL add content card', {
      eventSource,
      fields
    });
  });
}

export function trackAddFileCL(eventSource: ContentLibraryEventSource, fields?: string[]): void {
  safeCallSegment(() => {
    analytics.track('CL add file', {
      eventSource,
      fields
    });
  });
}

export function trackEditContentCardCL(
  eventSource: ContentLibraryEventSource,
  mode: 'Single' | 'Bulk',
  fields: string[],
  contentType?: ContentLibraryType
): void {
  safeCallSegment(() => {
    analytics.track('CL edit content card', {
      eventSource,
      mode,
      fields,
      contentType
    });
  });
}

export function trackDeleteContentCardCL(eventSource: ContentLibraryEventSource, mode: 'Single' | 'Bulk'): void {
  safeCallSegment(() => {
    analytics.track('CL delete content card', {
      eventSource,
      mode
    });
  });
}

export function trackOpenContentLibraryCL(responseType?: ResponseType): void {
  safeCallSegment(() => {
    analytics.track('CL open content library', {
      responseType
    });
  });
}

import React, { FC, useCallback, useMemo, memo, KeyboardEvent } from 'react';
import { Form, Input } from 'src/common';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import { IBlockEditProps } from 'src/shared/Tender/Boxes/types';
import ValueActions from '../../ValueActions';
import styles from './index.module.scss';

const { TextArea } = Input;

interface IFormData {
  string: string;
}

export interface IProps extends IBlockEditProps<IBoxFieldString> {
  autoFocus?: boolean;
  autoSize?: boolean | AutoSizeType;
}
export const EditableBlockTextSimple: FC<IProps> = ({
  onChange,
  onFinish,
  field,
  containerRef,
  className,
  autoFocus,
  autoSize
}) => {
  const { id: fieldId, string } = field;
  const { t } = useTranslation();

  const [form] = useForm();

  const onChangeValues = useCallback(() => {
    const value: IFormData = form.getFieldValue(fieldId);

    value.string !== field.string
      ? onChange && onChange(field.update(value.string !== '' ? value.string : null))
      : onFinish && onFinish();
  }, [field, fieldId, form, onChange, onFinish]);

  const onSubmit = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      const eventTypes = ['click', 'mousedown', 'touchstart'];
      if ((e.key === 'Enter' && !e.shiftKey) || eventTypes.includes(e.type)) {
        onChangeValues();
      }
    },
    [onChangeValues]
  );
  const onCancel = useCallback(() => {
    form.resetFields();
    onFinish && onFinish();
  }, [form, onFinish]);

  const initialValues = useMemo(() => {
    return {
      [fieldId]: {
        string
      }
    };
  }, [fieldId, string]);

  return (
    <div ref={containerRef} className={className}>
      <Form form={form} initialValues={initialValues} className={styles.container}>
        <div className={styles.field}>
          <Form.Item name={[fieldId, 'string']} className={styles.input}>
            <TextArea
              autoSize={autoSize || true}
              onPressEnter={e => onSubmit(e)}
              placeholder={t('Common.Blocks.String')}
              autoFocus={autoFocus}
              onFocus={e => autoFocus && e.currentTarget.select()}
              className={styles.control}
            />
          </Form.Item>
          <ValueActions onChange={onChangeValues} onCancel={onCancel} />
        </div>
      </Form>
    </div>
  );
};

export default memo(EditableBlockTextSimple);

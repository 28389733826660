import { NetworkStatus, QueryResult, useQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IBidIdentifier } from 'src/models/bids/Bids/types';
import useSelectedTenders from 'src/reactiveVars/SelectedTendersVar';
import { DndType } from 'src/types/dnd';
import {
  ContractEndRangeMode,
  ContractEndSearchType,
  ExpiringContractsTier,
  JunctionLogic,
  KeywordWidth,
  ProfileType,
  SearchOnlyStatus,
  getExpiringContractsTier,
  AwardStatus,
  FeatureFlag
} from '@tendium/prom-types';
import { BidItemType } from '@tendium/prom-types/tender';
import { TendersTableItem } from '../TendersViews';
import { GET_EXPIRING_CONTRACTS, GET_SIMPLE_TENDERS } from './queries';
import {
  ApiTendersData,
  ApiTendersDataFull,
  ApiTendersItem,
  ApiTendersVars,
  ITendersDndItem,
  MappedTendersTypes,
  TendersSearchParams,
  TendersSortName,
  TendersSortOrder,
  TendersTypes,
  TendersTypesVars,
  TendersVars,
  UseApiTendersCtxOptions,
  isBuyerType,
  isContractValueRange,
  isDateRange,
  isJunctionLogic,
  isKeywordGroups,
  isKeywordWidth,
  isKeywords,
  isSupplierOrgs,
  isSupplierStrings,
  isTendersSort,
  isContractEnd,
  TendersFilterStatus,
  TendersURLQueryParams,
  UpdateTenderSearchVarsOptions,
  isContractEndDiff,
  ProfileDiffs,
  DEMO_PARAMS,
  isKeywordLanguages
} from './types';
import { useCommentsCount } from 'src/models/comments/hooks';
import { CommentParentType } from 'src/models/comments/types';
import { TendersListData } from '.';
import { createApiTendersCtx } from './context';
import {
  isBoolean,
  isString,
  isStrings,
  notUndefined,
  updateQueryParams,
  useQueryParams,
  splitByQuotation,
  isNumbers,
  isNumber,
  useMyFeaturesAndOwnership,
  useFeatureFlag
} from 'src/helpers';
import { useParams } from 'react-router';
import { Ownerships, ownership } from 'src/models/ownership';
import { toContractEnd } from 'src/models/matchingProfiles/helpers';
import {
  getQueryParamsFromTenderStatus,
  checkExpiringContracts,
  toSupplierIds,
  toSupplierStrings,
  toEcParams
} from './helpers';
import { MatchingProfile } from 'src/models/matchingProfiles';
import { isEqual } from 'lodash';

export function useOriginsIdsFromSelectedTenders(): string[] {
  const [selectedTenders] = useSelectedTenders();
  return useMemo(() => selectedTenders.filter(tender => !tender.bid).map(tender => tender.id), [selectedTenders]);
}

export function useBidIdsFromSelectedTenders(): IBidIdentifier[] {
  const [selectedTenders] = useSelectedTenders();
  return useMemo(() => selectedTenders.map(tender => tender.bid).filter(notUndefined), [selectedTenders]);
}

export function useOriginsIdsForDND(tender: TendersTableItem): string[] | undefined {
  const [selectedTenders] = useSelectedTenders();
  const originIds = useOriginsIdsFromSelectedTenders();

  return useMemo(() => {
    if (!!selectedTenders.length) {
      return originIds;
    } else if (tender.bid) {
      return;
    } else {
      return [tender.id];
    }
  }, [selectedTenders.length, tender.bid, tender.id, originIds]);
}

export function useBidsIdsForDND(tender: TendersTableItem): IBidIdentifier[] | undefined {
  const [selectedTenders] = useSelectedTenders();
  const bidIds = useBidIdsFromSelectedTenders();

  return useMemo(() => {
    if (!!selectedTenders.length) {
      return bidIds;
    } else if (tender.bid) {
      return [tender.bid];
    } else {
      return;
    }
  }, [bidIds, selectedTenders.length, tender.bid]);
}

export function useDNDItem(tender: TendersTableItem): ITendersDndItem {
  const originIds = useOriginsIdsForDND(tender);
  const bidIds = useBidsIdsForDND(tender);

  return {
    type: DndType.TendersItem,
    dndItem: tender,
    bidIds,
    originIds,
    originType: BidItemType.Procurement
  };
}

export type UseApiTenders<TVars, G = ApiTendersData> = [
  Pick<QueryResult<G, ApiTendersVars>, 'fetchMore' | 'loading' | 'error' | 'refetch'> & {
    data?: TendersListData<ApiTendersItem>;
    fetchingMore: boolean;
    vars: TVars;
    initVars: TVars;
    apiVars: QueryResult<G, ApiTendersVars>['variables'];
    eventSource: TendersTypes;
  },
  (updVars: Partial<TVars>, options?: UpdateTenderSearchVarsOptions) => void
];

export function useApiTenders<
  TVars = TendersVars,
  T extends keyof MappedTendersTypes | void = void
>(): UseApiTendersTypeFactory<TVars, T> {
  const context = useContext<UseApiTendersTypeFactory<TVars, T> | null>(createApiTendersCtx<TVars, T>());
  if (!context) {
    throw new Error('useApiTenders must be used under ApiTendersProvider');
  }
  return context;
}

export type UseApiTendersTypeFactory<TVars, T extends keyof MappedTendersTypes | void = void> = T extends 'ExpContract'
  ? UseApiTenders<TVars, ApiTendersDataFull>
  : UseApiTenders<TVars>;

export type UseApiTendersCtxReturnType<T extends keyof MappedTendersTypes> = UseApiTendersTypeFactory<
  MappedTendersTypes[T],
  T
>;

export function useApiTendersCtx<T extends keyof MappedTendersTypes>(
  type: T,
  externalInitVars?: MappedTendersTypes[T],
  mp?: MatchingProfile
): UseApiTendersCtxReturnType<T> {
  const [fetchComments, { data: commentsData }] = useCommentsCount();
  const options = useTendersVarsConfig(type, externalInitVars);

  const { query, initVars } = options;
  const qpVars = useTendersQpVars(type);
  const updateVars = useCallback(
    (vars: Partial<MappedTendersTypes[T]>, options?: UpdateTenderSearchVarsOptions) => {
      const changedKeys = Object.keys(vars);

      if (changedKeys.some(key => qpMapper[type].some(field => field === key))) {
        updateQueryParams(options?.tenderStatus ? { tenderStatus: options?.tenderStatus } : vars);
      }
    },
    [type]
  );

  const isAnalyticsPagesDemo = useFeatureFlag(FeatureFlag.Analytics_Pages_Demo);

  const ecParams = useEcParams(type, initVars, mp);

  useEffect(() => {
    if (checkExpiringContracts<T>(type) && mp?.id) {
      updateVars(ecParams);
    }

    if (checkExpiringContracts<T>(type) && isAnalyticsPagesDemo) updateVars(DEMO_PARAMS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mp, type]);

  const [_vars, _updateVars] = useState<MappedTendersTypes[T]>(ecParams);
  const page = initVars.offset ? initVars.offset + 1 : 1;
  const pageSize = initVars.amount ? initVars.amount : 0;

  const _mappedVars: TendersVars = useMemo(() => ({ ...initVars, ..._vars, ...qpVars }), [_vars, initVars, qpVars]);

  const variables: ApiTendersVars = useMemo(
    () => ({
      advanced: {
        assignedTo: _mappedVars?.assignedTo?.length ? _mappedVars?.assignedTo : undefined,
        awarded: _mappedVars?.awarded ? _mappedVars.awarded : undefined,
        buyerOrgIds: _mappedVars?.buyerOrgIds?.length ? _mappedVars?.buyerOrgIds : undefined,
        buyerSearchStrings: !_mappedVars?.buyerSearchStrings?.length ? undefined : _mappedVars?.buyerSearchStrings,
        contractEnd: toContractEnd(_mappedVars.contractEnd),
        contractValueRange:
          isNumber(_mappedVars?.contractValueRange?.min) ||
          isNumber(_mappedVars?.contractValueRange?.max) ||
          _mappedVars?.contractValueRange?.currency
            ? {
                min: isNumber(_mappedVars?.contractValueRange?.min)
                  ? Number(_mappedVars?.contractValueRange?.min)
                  : undefined,

                max: isNumber(_mappedVars?.contractValueRange?.max)
                  ? Number(_mappedVars?.contractValueRange?.max)
                  : undefined,

                currency: _mappedVars?.contractValueRange?.currency
              }
            : undefined,
        cpvCodesExact: _mappedVars?.cpvCodesExact?.length ? _mappedVars?.cpvCodesExact : undefined,
        keywordWidth: _mappedVars?.keywordWidth,
        filterKeywordLanguages: _mappedVars?.filterKeywordLanguages,
        highlightKeywordLanguages: _mappedVars?.highlightKeywordLanguages,
        keywordGroups: _mappedVars?.keywordGroups?.length
          ? _mappedVars?.keywordGroups.map(group => ({ ...group, groupId: undefined }))
          : undefined,
        keywordArgs: _mappedVars?.keywordArgs?.length ? _mappedVars?.keywordArgs : undefined, //NewKeyword[];
        name: _mappedVars?.name,
        nutsCodesExact: _mappedVars?.nutsCodesExact?.length ? _mappedVars?.nutsCodesExact : undefined,
        onlyFilledData: _mappedVars?.onlyFilledData,
        onlyStatus: _mappedVars?.onlyStatus ? _mappedVars.onlyStatus : undefined,
        procuringAgencyTypes: _mappedVars?.procuringAgencyTypes?.length ? _mappedVars?.procuringAgencyTypes : undefined,
        publicationDate: _mappedVars?.published
          ? {
              start: _mappedVars?.published[0],
              end: _mappedVars?.published[1]
            }
          : undefined,
        search: !!_mappedVars?.search ? splitByQuotation(_mappedVars?.search) : undefined,
        submissionDeadline: _mappedVars?.deadline
          ? {
              start: _mappedVars?.deadline[0],
              end: _mappedVars?.deadline[1]
            }
          : undefined,
        supplierOrgIds: _mappedVars?.supplierOrgIds?.length ? _mappedVars?.supplierOrgIds : undefined,
        supplierSearchLogic: _mappedVars?.supplierSearchLogic,
        supplierSearchStrings: _mappedVars?.supplierSearchStrings?.length
          ? _mappedVars?.supplierSearchStrings
          : undefined,
        contractValueExcludeMissingValue: _mappedVars?.contractValueExcludeMissingValue,
        cpvCodes: _mappedVars?.cpvCodes?.length ? _mappedVars?.cpvCodes : undefined,
        nutsCodes: _mappedVars?.nutsCodes?.length ? _mappedVars?.nutsCodes : undefined,
        buyerOrgId: _mappedVars?.buyerOrgId
      },
      sortOnField: _mappedVars?.sort,
      amount: pageSize,
      offset: (page - 1) * pageSize,
      matchingProfileId: _mappedVars.matchingProfileId,
      isUnhandled: _mappedVars?.isUnhandled ? _mappedVars.isUnhandled : undefined,
      isStarred: _mappedVars?.isStarred
    }),
    [
      _mappedVars?.assignedTo,
      _mappedVars.awarded,
      _mappedVars?.buyerOrgIds,
      _mappedVars?.buyerSearchStrings,
      _mappedVars.contractEnd,
      _mappedVars?.contractValueRange?.min,
      _mappedVars?.contractValueRange?.max,
      _mappedVars?.contractValueRange?.currency,
      _mappedVars?.cpvCodesExact,
      _mappedVars?.keywordWidth,
      _mappedVars?.filterKeywordLanguages,
      _mappedVars?.highlightKeywordLanguages,
      _mappedVars?.keywordGroups,
      _mappedVars?.keywordArgs,
      _mappedVars?.name,
      _mappedVars?.nutsCodesExact,
      _mappedVars?.onlyFilledData,
      _mappedVars.onlyStatus,
      _mappedVars?.procuringAgencyTypes,
      _mappedVars?.published,
      _mappedVars?.search,
      _mappedVars?.deadline,
      _mappedVars?.supplierOrgIds,
      _mappedVars?.supplierSearchLogic,
      _mappedVars?.supplierSearchStrings,
      _mappedVars?.contractValueExcludeMissingValue,
      _mappedVars?.cpvCodes,
      _mappedVars?.nutsCodes,
      _mappedVars?.buyerOrgId,
      _mappedVars?.sort,
      _mappedVars.matchingProfileId,
      _mappedVars.isUnhandled,
      _mappedVars?.isStarred,
      pageSize,
      page
    ]
  );

  const isExpiringContracts = checkExpiringContracts<T>(type);

  const queryDataExpContract = useQuery<ApiTendersDataFull, ApiTendersVars>(query ?? GET_EXPIRING_CONTRACTS, {
    variables: {
      ...variables,
      advanced: {
        ...variables.advanced,
        buyerOrgIds: isAnalyticsPagesDemo ? DEMO_PARAMS.buyerOrgIds : variables.advanced?.buyerOrgIds
      }
    },
    notifyOnNetworkStatusChange: true,
    skip: !variables || !isExpiringContracts
  });

  const queryDataSimple = useQuery<ApiTendersData, ApiTendersVars>(query ?? GET_SIMPLE_TENDERS, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: !variables || isExpiringContracts,
    onCompleted: async ({ getSimpleTenders }) => {
      const ids = getSimpleTenders?.procurementsWithScore.map(item => item.procurement.id);
      if (!!ids?.length) {
        await fetchComments({ variables: { ids, parentType: CommentParentType.Procurement } });
      }
    }
  });

  const updateVarsFn = useCallback(
    (_vars: Partial<MappedTendersTypes[T]>, options: UpdateTenderSearchVarsOptions) => {
      const _qpVars = Object.fromEntries(
        Object.entries(_vars).map(([key, value]) => [
          key,
          initVars[key as keyof Partial<MappedTendersTypes[T]>] === value ? undefined : value
        ])
      ) as Partial<MappedTendersTypes[T]>;

      updateVars?.(_qpVars, options);
      _updateVars(currentVars => ({ ...currentVars, ..._vars }));
    },
    [initVars, updateVars]
  );

  const queryData = isExpiringContracts ? queryDataExpContract : queryDataSimple;

  return useMemo(
    () =>
      [
        {
          eventSource: type,
          vars: _mappedVars,
          initVars,
          apiVars: queryData.variables,
          data: !!queryData.data
            ? new TendersListData(
                queryData.data[
                  isExpiringContracts
                    ? ('getTenders' as keyof typeof queryData.data)
                    : ('getSimpleTenders' as keyof typeof queryData.data)
                ],
                commentsData ?? []
              )
            : undefined,
          fetchMore: queryData.fetchMore,
          fetchingMore: queryData.networkStatus === NetworkStatus.fetchMore,
          refetch: queryData.refetch,
          loading:
            (queryData.loading ||
              queryData.data === undefined ||
              queryData.networkStatus === NetworkStatus.refetch ||
              queryData.networkStatus === NetworkStatus.setVariables) &&
            queryData.networkStatus !== NetworkStatus.fetchMore
        },
        updateVarsFn
      ] as UseApiTendersCtxReturnType<T>,
    [type, _mappedVars, initVars, queryData, isExpiringContracts, commentsData, updateVarsFn]
  );
}

export function useEcParams(
  type: keyof MappedTendersTypes,
  initVars: TendersVars,
  mp?: MatchingProfile
): Partial<TendersVars> {
  const expiringContractsTier = useExpiringContractsTier();
  return toEcParams(type, initVars, mp, expiringContractsTier);
}

export function useProfileDiff(vars: TendersVars, mp?: MatchingProfile): ProfileDiffs {
  const isKeywordsMpDiff = useMemo(() => {
    const mpKeywords =
      mp?.apiKeywords?.map(keyword => ({
        value: keyword.value,
        searchType: keyword.searchType,
        searchLogic: keyword.searchLogic,
        filterLogic: keyword.filterLogic,
        highlightColor: keyword.highlightColor
      })) ?? [];
    const varsKeywords =
      vars.keywordArgs?.map(keyword => ({
        value: keyword.value,
        searchType: keyword.searchType,
        searchLogic: keyword.searchLogic,
        filterLogic: keyword.filterLogic,
        highlightColor: keyword.highlightColor
      })) ?? [];
    return mp?.profileType === ProfileType.ExpiringContract && !isEqual(mpKeywords, varsKeywords);
  }, [mp?.apiKeywords, mp?.profileType, vars.keywordArgs]);

  const isKeywordGroupsMpDiff = useMemo(() => {
    const mpKeywordGroups =
      mp?.apiKeywordGroups?.map(keyword => ({
        values: keyword.values.map(item => item.value),
        searchType: keyword.searchType,
        searchLogic: keyword.searchLogic,
        filterLogic: keyword.filterLogic,
        highlightColor: keyword.highlightColor
      })) ?? [];
    const varsKeywordGroups =
      vars.keywordGroups?.map(keyword => ({
        values: keyword.values,
        searchType: keyword.searchType,
        searchLogic: keyword.searchLogic,
        filterLogic: keyword.filterLogic,
        highlightColor: keyword.highlightColor
      })) ?? [];
    return mp?.profileType === ProfileType.ExpiringContract && !isEqual(mpKeywordGroups, varsKeywordGroups);
  }, [mp?.apiKeywordGroups, mp?.profileType, vars.keywordGroups]);

  const isKeywordWidthMpDiff = useMemo(
    () => mp?.profileType === ProfileType.ExpiringContract && !isEqual(mp?.keywordWidth, vars.keywordWidth),
    [mp?.keywordWidth, mp?.profileType, vars.keywordWidth]
  );

  const isKeywordLanguageDiff = useMemo(
    () =>
      mp?.profileType === ProfileType.ExpiringContract &&
      (!isEqual(mp?.filterKeywordLanguages, vars.filterKeywordLanguages) ||
        !isEqual(mp?.highlightKeywordLanguages, vars.highlightKeywordLanguages)),
    [
      mp?.filterKeywordLanguages,
      mp?.highlightKeywordLanguages,
      mp?.profileType,
      vars.filterKeywordLanguages,
      vars.highlightKeywordLanguages
    ]
  );

  const isSuppliersMpDiff = useMemo(
    () =>
      mp?.profileType === ProfileType.ExpiringContract &&
      (!isEqual(
        vars.supplierOrgIds ?? [],
        !!mp.supplierSearchTerms?.length ? toSupplierIds(mp.supplierSearchTerms) : []
      ) ||
        !isEqual(
          vars.supplierSearchStrings ?? [],
          !!mp.supplierSearchTerms?.length ? toSupplierStrings(mp.supplierSearchTerms) : []
        ) ||
        vars.supplierSearchLogic !== mp.supplierSearchLogic),
    [mp, vars.supplierOrgIds, vars.supplierSearchLogic, vars.supplierSearchStrings]
  );

  const isBuyersMpDiff = useMemo(
    () =>
      mp?.profileType === ProfileType.ExpiringContract &&
      (!isEqual(vars.buyerOrgIds ?? [], mp?.buyerOrgIds ?? []) ||
        !isEqual(vars.buyerSearchStrings ?? [], mp?.buyerSearchStrings ?? []) ||
        !isEqual(vars.procuringAgencyTypes ?? [], mp?.buyerTypes ?? [])),
    [mp, vars.buyerOrgIds, vars.buyerSearchStrings, vars.procuringAgencyTypes]
  );

  const isContractValuesMpDiff = useMemo(() => {
    const mpMin = mp?.contractValueMin ?? null;
    const mpMax = mp?.contractValueMax ?? null;
    const mpCurrency = mp?.contractValueCurrency?.length ? mp?.contractValueCurrency : null;
    const varsMin = !!vars.contractValueRange?.min ? vars.contractValueRange?.min : null;
    const varsMax = !!vars.contractValueRange?.max ? vars.contractValueRange?.max : null;
    const varsCurrency = !!vars.contractValueRange?.currency ? vars.contractValueRange?.currency : null;

    return (
      mp?.profileType === ProfileType.ExpiringContract &&
      (varsMin !== mpMin ||
        varsMax !== mpMax ||
        varsCurrency !== mpCurrency ||
        vars.contractValueExcludeMissingValue !== mp?.contractValueExcludeMissingValue)
    );
  }, [
    mp?.contractValueCurrency,
    mp?.contractValueExcludeMissingValue,
    mp?.contractValueMax,
    mp?.contractValueMin,
    mp?.profileType,
    vars.contractValueExcludeMissingValue,
    vars.contractValueRange?.currency,
    vars.contractValueRange?.max,
    vars.contractValueRange?.min
  ]);

  const isCpvsMpDiff = useMemo(
    () =>
      mp?.profileType === ProfileType.ExpiringContract &&
      (!isEqual(vars.cpvCodes ?? [], mp?.cpvCodes ?? []) ||
        !isEqual(vars.cpvCodesExact ?? [], mp?.cpvCodesExact ?? [])),
    [mp, vars.cpvCodes, vars.cpvCodesExact]
  );

  const isNutsMpDiff = useMemo(
    () =>
      mp?.profileType === ProfileType.ExpiringContract &&
      (!isEqual(vars.nutsCodes ?? [], mp?.nutsCodes ?? []) ||
        !isEqual(vars.nutsCodesExact ?? [], mp?.nutsCodesExact ?? [])),
    [mp, vars.nutsCodes, vars.nutsCodesExact]
  );

  const isContractEndMpDiff = useMemo(() => {
    return mp?.profileType === ProfileType.ExpiringContract && isContractEndDiff(mp.contractEnd, vars.contractEnd);
  }, [mp, vars]);

  return {
    isKeywordsMpDiff,
    isKeywordGroupsMpDiff,
    isKeywordWidthMpDiff,
    isSuppliersMpDiff,
    isBuyersMpDiff,
    isContractValuesMpDiff,
    isCpvsMpDiff,
    isNutsMpDiff,
    isContractEndMpDiff,
    isKeywordLanguageDiff
  };
}

export function useProfileChanged(vars: TendersVars, mp?: MatchingProfile): boolean {
  const {
    isKeywordsMpDiff,
    isKeywordGroupsMpDiff,
    isKeywordWidthMpDiff,
    isSuppliersMpDiff,
    isBuyersMpDiff,
    isContractValuesMpDiff,
    isCpvsMpDiff,
    isNutsMpDiff,
    isContractEndMpDiff
  } = useProfileDiff(vars, mp);

  return useMemo(
    () =>
      mp?.profileType === ProfileType.ExpiringContract &&
      (isKeywordsMpDiff ||
        isKeywordGroupsMpDiff ||
        isKeywordWidthMpDiff ||
        isSuppliersMpDiff ||
        isBuyersMpDiff ||
        isContractValuesMpDiff ||
        isCpvsMpDiff ||
        isNutsMpDiff ||
        isContractEndMpDiff),
    [
      isBuyersMpDiff,
      isContractValuesMpDiff,
      isCpvsMpDiff,
      isKeywordGroupsMpDiff,
      isKeywordWidthMpDiff,
      isKeywordsMpDiff,
      isNutsMpDiff,
      isSuppliersMpDiff,
      isContractEndMpDiff,
      mp?.profileType
    ]
  );
}

export function useTendersQpVars(type: keyof MappedTendersTypes): TendersVars {
  const qp = useQueryParams();
  const deadlineSearchParams = qp[TendersSearchParams.deadline];
  const publishedSearchParams = qp[TendersSearchParams.published];
  const assignedSearchParams = qp[TendersSearchParams.assignedTo];
  const cpvSearchParams = qp[TendersSearchParams.cpvCodes];
  const nutsSearchParams = qp[TendersSearchParams.nutsCodes];
  const buyerOrgIdsParams = qp[TendersSearchParams.buyerOrgIds];
  const buyerSearchStringsParams = qp[TendersSearchParams.buyerSearchStrings];
  const keywordsSearchParams = qp[TendersSearchParams.keywordArgs];
  const keywordGroupsSearchParams = qp[TendersSearchParams.keywordGroups];
  const contractEndParams = qp[TendersSearchParams.contractEnd];
  const contractValueRangeSearchParams = qp[TendersSearchParams.contractValueRange];
  const supplierOrgsSearchParams = qp[TendersSearchParams.supplierOrgIds];
  const supplierStringsSearchParams = qp[TendersSearchParams.supplierSearchStrings];
  const buyerTypesSearchParams = qp[TendersSearchParams.procuringAgencyTypes];
  const filterKeywordLanguages = qp[TendersSearchParams.filterKeywordLanguages];
  const highlightKeywordLanguages = qp[TendersSearchParams.highlightKeywordLanguages];

  const all = useMemo(
    () => ({
      sort: isTendersSort(qp[TendersSearchParams.sort]) ? qp[TendersSearchParams.sort] : undefined,
      isUnhandled: isBoolean(qp[TendersSearchParams.isUnhandled]) ? qp[TendersSearchParams.isUnhandled] : undefined,
      search: isString(qp[TendersSearchParams.search]) ? qp[TendersSearchParams.search] : undefined,
      name: isString(qp[TendersSearchParams.name]) ? qp[TendersSearchParams.name] : undefined,
      buyerOrgIds:
        isStrings(buyerOrgIdsParams) || isNumbers(buyerOrgIdsParams)
          ? buyerOrgIdsParams.map(id => id.toString())
          : undefined,
      buyerSearchStrings: isStrings(buyerSearchStringsParams) ? buyerSearchStringsParams : undefined,
      cpvCodes: isStrings(cpvSearchParams) ? cpvSearchParams : undefined,
      nutsCodes: isStrings(nutsSearchParams) ? nutsSearchParams : undefined,
      deadline: isDateRange(deadlineSearchParams) ? deadlineSearchParams : undefined,
      published: isDateRange(publishedSearchParams) ? publishedSearchParams : undefined,
      assignedTo: isStrings(assignedSearchParams) ? assignedSearchParams : undefined,
      profileId: isString(qp[TendersSearchParams.profileId]) ? qp[TendersSearchParams.profileId] : undefined,
      keywordArgs: isKeywords(keywordsSearchParams) ? keywordsSearchParams : undefined,
      keywordWidth: isKeywordWidth(qp[TendersSearchParams.keywordWidth])
        ? qp[TendersSearchParams.keywordWidth]
        : undefined,
      filterKeywordLanguages: isKeywordLanguages(filterKeywordLanguages) ? filterKeywordLanguages : [],
      highlightKeywordLanguages: isKeywordLanguages(highlightKeywordLanguages) ? highlightKeywordLanguages : [],
      keywordGroups: isKeywordGroups(keywordGroupsSearchParams) ? keywordGroupsSearchParams : undefined,
      contractEnd: isContractEnd(contractEndParams) ? toContractEnd(contractEndParams) : undefined,
      contractValueRange: isContractValueRange(contractValueRangeSearchParams)
        ? contractValueRangeSearchParams
        : undefined,
      supplierOrgIds: isSupplierOrgs(supplierOrgsSearchParams)
        ? supplierOrgsSearchParams.map(sup => ({ ...sup, orgId: sup.orgId.toString() }))
        : undefined,
      supplierSearchStrings: isSupplierStrings(supplierStringsSearchParams) ? supplierStringsSearchParams : undefined,
      supplierSearchLogic: isJunctionLogic(qp[TendersSearchParams.supplierSearchLogic])
        ? qp[TendersSearchParams.supplierSearchLogic]
        : undefined,
      contractValueExcludeMissingValue: isBoolean(qp[TendersSearchParams.contractValueExcludeMissingValue])
        ? qp[TendersSearchParams.contractValueExcludeMissingValue]
        : undefined,
      procuringAgencyTypes:
        Array.isArray(buyerTypesSearchParams) && buyerTypesSearchParams.every(type => isBuyerType(type))
          ? buyerTypesSearchParams
          : undefined,
      ...getQueryParamsFromTenderStatus(qp[TendersURLQueryParams.tenderStatus] as TendersFilterStatus)
    }),
    [
      assignedSearchParams,
      buyerOrgIdsParams,
      buyerSearchStringsParams,
      buyerTypesSearchParams,
      contractEndParams,
      contractValueRangeSearchParams,
      cpvSearchParams,
      deadlineSearchParams,
      filterKeywordLanguages,
      highlightKeywordLanguages,
      keywordGroupsSearchParams,
      keywordsSearchParams,
      nutsSearchParams,
      publishedSearchParams,
      qp,
      supplierOrgsSearchParams,
      supplierStringsSearchParams
    ]
  );

  return useMemo(
    () =>
      Object.fromEntries(
        Object.entries(all).filter(([key, value]) => qpMapper[type].some(field => field === key) && value !== undefined)
      ),
    [all, type]
  );
}

const qpMapper: TendersTypesVars = {
  All: [
    'assignedTo',
    'awarded',
    'buyerOrgIds',
    'buyerSearchStrings',
    'cpvCodes',
    'deadline',
    'nutsCodes',
    'onlyStatus',
    'published',
    'search',
    'sort'
  ],
  Starred: ['awarded', 'onlyStatus', 'search', 'sort'],
  Mp: ['awarded', 'isUnhandled', 'onlyStatus', 'search', 'sort'],
  BuyerExpired: [],
  BuyerRecent: [],
  ExpContract: [
    'profileId',
    'buyerOrgIds',
    'cpvCodes',
    'nutsCodes',
    'search',
    'procuringAgencyTypes',
    'buyerSearchStrings',
    'contractEnd',
    'contractValueRange',
    'contractValueExcludeMissingValue',
    'keywordArgs',
    'keywordWidth',
    'filterKeywordLanguages',
    'highlightKeywordLanguages',
    'keywordGroups',
    'supplierOrgIds',
    'supplierSearchLogic',
    'supplierSearchStrings'
  ],
  SupplierProfile: []
};

const INIT_TENDERS_VARS: TendersVars = {
  amount: 30,
  offset: 0,
  sort: {
    key: TendersSortName.published,
    sort: TendersSortOrder.DESCENDING
  }
};

const INIT_LIGHT_CONTRACT_END_RANGE = {
  relativeStart: 6,
  relativeEnd: 18
};

export function useTendersVarsConfig<T extends keyof MappedTendersTypes>(
  type: T,
  externalInitVars?: MappedTendersTypes[T]
): UseApiTendersCtxOptions<MappedTendersTypes[T]> {
  const { orgId, mpId } = useParams<{ orgId: string; mpId: string }>();
  const expiringContractsTier = useExpiringContractsTier();
  const qp = useQueryParams();

  return useMemo(() => {
    switch (type) {
      case 'All': {
        return {
          initVars: {
            ...INIT_TENDERS_VARS,
            onlyStatus:
              qp[TendersURLQueryParams.tenderStatus] === TendersFilterStatus.all ||
              qp[TendersURLQueryParams.tenderStatus] === TendersFilterStatus.awarded
                ? undefined
                : SearchOnlyStatus.Recent,
            ...(externalInitVars ?? {})
          }
        };
      }
      case 'Starred': {
        return { initVars: { ...INIT_TENDERS_VARS, isStarred: true } };
      }
      case 'Mp': {
        return {
          initVars: {
            ...INIT_TENDERS_VARS,
            matchingProfileId: mpId,
            onlyStatus:
              qp[TendersURLQueryParams.tenderStatus] === TendersFilterStatus.all ||
              qp[TendersURLQueryParams.tenderStatus] === TendersFilterStatus.awarded
                ? undefined
                : SearchOnlyStatus.Recent,
            isUnhandled: true,
            ...(externalInitVars ?? {})
          }
        };
      }
      case 'BuyerExpired': {
        return {
          initVars: {
            ...INIT_TENDERS_VARS,
            amount: 5,
            onlyStatus: SearchOnlyStatus.Expired,
            buyerOrgId: orgId,
            ...(externalInitVars ?? {})
          }
        };
      }
      case 'BuyerRecent': {
        return {
          initVars: {
            ...INIT_TENDERS_VARS,
            amount: 5,
            onlyStatus: SearchOnlyStatus.Recent,
            buyerOrgId: orgId,
            ...(externalInitVars ?? {})
          }
        };
      }
      case 'ExpContract': {
        return {
          initVars: {
            matchingProfileId: mpId,
            isUnhandled: !!mpId,
            amount: 30,
            offset: 0,
            sort: {
              key: TendersSortName.expiration,
              sort: TendersSortOrder.ASCENDING
            },
            contractEnd: {
              searchMode: ContractEndSearchType.LastPossibleEndDate,
              rangeMode: ContractEndRangeMode.RELATIVE,
              ...(expiringContractsTier === ExpiringContractsTier.Light ? INIT_LIGHT_CONTRACT_END_RANGE : {})
            },
            supplierSearchLogic: JunctionLogic.OR,
            keywordWidth: ownership.name === Ownerships.Byggfakta ? KeywordWidth.Narrow : KeywordWidth.Broad,
            filterKeywordLanguages: [],
            highlightKeywordLanguages: [],
            ...(externalInitVars ?? {})
          }
        };
      }
      case 'SupplierProfile': {
        return {
          initVars: {
            amount: 30,
            offset: 0,
            sort: {
              key: TendersSortName.expiration,
              sort: TendersSortOrder.ASCENDING
            },
            supplierSearchLogic: JunctionLogic.OR,
            keywordWidth: ownership.name === Ownerships.Byggfakta ? KeywordWidth.Narrow : KeywordWidth.Broad,
            // Required by awarded gantt graph
            supplierOrgIds: orgId
              ? [
                  {
                    orgId,
                    status: AwardStatus.Won // means the procurement was awarded to this supplier
                  }
                ]
              : undefined,
            // default value for tender status filter
            contractEnd: undefined,
            // default value for profile filter
            profileId: undefined,
            ...(externalInitVars ?? {})
          }
        };
      }

      default: {
        return { initVars: INIT_TENDERS_VARS };
      }
    }
  }, [expiringContractsTier, externalInitVars, mpId, orgId, qp, type]);
}

export function useExpiringContractsTier(): ExpiringContractsTier {
  const { myFeatureFlags } = useMyFeaturesAndOwnership();

  return getExpiringContractsTier(myFeatureFlags);
}

import { IAnswer, ApiBidTask, RequirementType, TaskFulfillment, QuestionStatus, ResponseType } from './types';
import { IApiComment } from 'src/models/comments/types';
import { BidItemType } from '@tendium/prom-types/tender';
import { BidQuestionType } from '../BidFull/types';
import { toPdfHighlightsData } from 'src/shared/DocumentViewer/helpers';
import { IHighlight } from 'react-pdf-highlighter';

export class BidTask {
  public readonly id: string;
  public readonly title: string | null;
  public readonly question: string | null;
  public readonly groupId: string;
  public readonly groupTitle: string;
  public readonly groupFilePath?: string;
  public readonly groupIsGenerated?: string;
  public readonly assignedTo: string | null;
  public readonly requirementType: RequirementType | null;
  public readonly fulfilment: TaskFulfillment | null;
  public readonly responseType: ResponseType | null;
  public readonly deadline: number | null;
  public readonly answer: IAnswer | null;
  public readonly headline: string | null;
  public readonly content: string | null;
  public readonly status: QuestionStatus;
  public readonly isSubmitted: boolean | null;
  public readonly comments: IApiComment[];
  public readonly isGenerated: boolean;
  public readonly highlights: IHighlight[];
  public readonly filePath: string;
  public readonly bidId: string;
  public readonly originId: string;
  public readonly originType: BidItemType;
  public readonly type: BidQuestionType;
  public readonly classificationId?: string;

  constructor(private readonly apiBidQuestion: ApiBidTask) {
    const { questionGroup } = apiBidQuestion;
    this.id = apiBidQuestion.id;
    this.bidId = questionGroup.bid.id;
    this.originId = questionGroup.bid.itemId;
    this.originType = questionGroup.bid.itemType;
    this.classificationId = apiBidQuestion.classificationId;
    this.groupId = questionGroup.id;
    this.groupTitle = questionGroup.title;
    this.groupFilePath = questionGroup.filePath;
    this.groupIsGenerated = questionGroup.isGenerated;
    this.headline = apiBidQuestion.document ? apiBidQuestion.document.headline : null;
    this.filePath = apiBidQuestion.document ? apiBidQuestion.document.path : '';
    this.title = apiBidQuestion.title;
    this.assignedTo = apiBidQuestion.assignedTo ? apiBidQuestion.assignedTo.id : null;
    this.requirementType = apiBidQuestion.requirementType;
    this.fulfilment = apiBidQuestion.fulfilment;
    this.responseType = apiBidQuestion.responseType;
    this.answer = apiBidQuestion.answer;
    this.content = !!apiBidQuestion.content ? apiBidQuestion.content : null;
    this.question = !!apiBidQuestion.question ? apiBidQuestion.question : null;
    this.status = apiBidQuestion.status;
    this.isSubmitted = apiBidQuestion.isSubmitted;
    this.comments = apiBidQuestion.comments;
    this.isGenerated = apiBidQuestion.isGenerated;
    this.highlights = this.apiBidQuestion.document
      ? toPdfHighlightsData(this.apiBidQuestion.document.headlinePosition, this.apiBidQuestion.document.contentPosition)
      : [];
    this.type = apiBidQuestion.isGenerated ? BidQuestionType.Generated : BidQuestionType.Manual;
    this.deadline = !!apiBidQuestion.deadline ? apiBidQuestion.deadline : null;
  }
}

import { CommentsCount } from '../../comments/types';
import { ApiTendersItem, ApiTendersResponse, TendersItem } from './types';

export class TendersListData<TData extends ApiTendersItem> {
  readonly count: number;
  readonly tenders: TendersItem<TData>[];

  constructor(
    private readonly apiTendersResponse: ApiTendersResponse<TData>,
    private readonly commentsCount: CommentsCount[]
  ) {
    this.count = apiTendersResponse.count;
    this.tenders = this.toTendersData();
  }

  private toTendersData(): TendersItem<TData>[] {
    const apiTendersWithScore = this.apiTendersResponse.procurementsWithScore;
    if (!apiTendersWithScore) {
      return [];
    }
    return apiTendersWithScore.map(({ procurement: apiTender, highlights, matchedCpvs, score }, index) => ({
      ...apiTender,
      isCommented: this.commentsCount.some(commentCount => commentCount.parentId === apiTender.id),
      highlights,
      matchedCpvs,
      score,
      nextId: apiTendersWithScore[index + 1]?.procurement.id
    }));
  }
}

import React, { FC, useMemo, useCallback, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Skeleton, StackTimeline } from 'src/common';
import { useFetchMore } from 'src/common/infinityScroll';
import { MultiBuyerBoxes, LoadMoreButton, WorkspacePicker } from 'src/shared';
import ReadOnlyBlockCurrency from 'src/shared/Blocks/Currency/ReadOnlyBlock';
import { StackSerie, StackSerieRange } from 'src/common/StackTimeline/types';
import { useOpenTenderPreview } from 'src/models/procurements/hooks';
import dayjs from 'src/helpers/dayjs';
import TooltipContentRanges from '../TooltipContentRanges';
import { useApiTenders, useProfileChanged } from 'src/models/procurements/Tenders/hooks';
import { TendersTimelineItem, useTendersTimeline } from 'src/models/procurements/TendersViews';
import { getEndDate, getStartDate, toBuyerName } from 'src/models/procurements/helpers';
import classNames from 'classnames';
import TenderRejectedBadge from 'src/shared/Tender/TenderRejectedBadge';
import { BidItemType } from '@tendium/prom-types/tender';
import OverviewBoardActionsSingle from 'src/pages/TendersPage/TendersBoard/ActionsSingle';
import { useTenderActionEventSource } from 'src/segment/hooks';
import { useLoadMp } from 'src/models/matchingProfiles/hooks';
import { useParams } from 'react-router';
import EmptyResults from '../EmptyResults';

interface Props {
  tooltipContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  showTenderActions?: boolean;
}

export const ExpiringContracts: FC<Props> = ({ tooltipContainerRef, showTenderActions = false }) => {
  const { t } = useTranslation();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [{ fetchMore, fetchingMore, apiVars: variables, loading }] = useApiTenders();
  const { data: tendersData } = useTendersTimeline();
  const eventSource = useTenderActionEventSource();

  const loadMore = useCallback(
    async (v: { offset: number }) => {
      return fetchMore({
        variables: v
      });
    },
    [fetchMore]
  );

  const data = useMemo(() => tendersData?.tenders ?? [], [tendersData]);
  const onLoadMore = useFetchMore({ offset: data.length ?? 0 }, loadMore);
  const total = useMemo(() => tendersData?.count ?? 0, [tendersData]);
  const hasMore = data.length < total;
  const openTenderPreview = useOpenTenderPreview();

  const { mpId } = useParams<{ mpId: string }>();
  const { data: mp } = useLoadMp(mpId);
  const [{ vars }] = useApiTenders();
  const isProfileChanged = useProfileChanged(vars, mp);

  const onClickName = useCallback(
    (id: string) => {
      openTenderPreview(id);
    },
    [openTenderPreview]
  );

  const focusDate = useMemo(() => {
    return variables?.advanced ? getStartDate(variables?.advanced) : undefined;
  }, [variables]);

  const startDate = useMemo(() => {
    const start = variables?.advanced ? getStartDate(variables?.advanced) : undefined;
    return start ? dayjs(start).subtract(10, 'year').valueOf() : undefined;
  }, [variables]);

  const endDate = useMemo(() => {
    const end = variables?.advanced ? getEndDate(variables?.advanced) : undefined;
    return end ? dayjs(end).add(10, 'year').valueOf() : undefined;
  }, [variables]);

  return loading ? (
    <Skeleton loading active />
  ) : !data.length ? (
    <EmptyResults />
  ) : (
    <div ref={setContainer} className={styles.container}>
      {!!data.length && (
        <StackTimeline
          axises={['quarter', 'month']}
          data={data}
          focusDate={focusDate}
          startDate={startDate}
          endDate={endDate}
          onClickTitle={onClickName}
          label={(d: StackSerie<TendersTimelineItem>) => (
            <div className={styles.labelContainer}>
              {d.data.name}
              {showTenderActions && (
                <div className={styles.animationContainer}>
                  <div className={classNames(styles.toolbar, { [styles.hide]: d.data.isRejected })}>
                    {d.data.isRejected && !d.data.bid?.wsId && <TenderRejectedBadge />}
                    {d.data.bid?.wsId && (
                      <WorkspacePicker
                        originIds={d.data.bid ? undefined : [d.data.id]}
                        bidIds={d.data.bid ? [d.data.bid] : undefined}
                        originType={d.data.bid ? undefined : BidItemType.Procurement}
                        eventSource={eventSource}
                        eventType={'OverviewBoardActionsSingle'}
                        mode={'medium'}
                        size={'xs'}
                        disabled={isProfileChanged}
                        relevanceScores={[d.data.apiTender.score]}
                      />
                    )}
                    <div className={styles.animationMainItem}>
                      <MultiBuyerBoxes buyerBoxes={d.data.buyers} size={'s'} noLink noTooltip />
                    </div>
                  </div>
                  <div
                    className={classNames(styles.animationSecItem, {
                      [styles.shiftLeft]: d.data.bid?.wsId
                    })}
                  >
                    <OverviewBoardActionsSingle
                      tender={d.data.apiTender}
                      showStarred={false}
                      disableActions={isProfileChanged}
                    />
                  </div>
                </div>
              )}
              {!showTenderActions && <MultiBuyerBoxes buyerBoxes={d.data.buyers} size={'s'} noLink noTooltip />}
            </div>
          )}
          rowTooltip={(d: StackSerie<TendersTimelineItem>) => (
            <div className={styles.tooltip}>
              {d.data.buyers?.map(buyerBox => (
                <div key={buyerBox.id} className={styles.tooltipLabel}>
                  {toBuyerName(buyerBox)}
                </div>
              ))}
              {d.data.estValue && (
                <ReadOnlyBlockCurrency
                  amount={d.data.estValue.amount}
                  currency={d.data.estValue.currency}
                  className={styles.tooltipData}
                />
              )}
              <span className={styles.tooltipData}>{t('Common.duration')}</span>
              <span className={styles.tooltipLabel}>
                {t('Common.Format.tableDateFormatForShortMonths', { date: d.ranges[0]?.label.from })} {t('Common.to')}{' '}
                {t('Common.Format.tableDateFormatForShortMonths', { date: d.ranges[d.ranges.length - 1]?.label.to })}
              </span>

              {d.ranges.map((range, index) => {
                return <TooltipContentRanges range={range} count={index} key={index} />;
              })}
            </div>
          )}
          barTooltip={(range: StackSerieRange, index: number, d: StackSerie<TendersTimelineItem>) => (
            <div className={styles.tooltip}>
              {d.data.buyers?.map(buyerBox => (
                <div key={buyerBox.id} className={styles.tooltipLabel}>
                  {toBuyerName(buyerBox)}
                </div>
              ))}
              {d.data.estValue && (
                <ReadOnlyBlockCurrency
                  amount={d.data.estValue.amount}
                  currency={d.data.estValue.currency}
                  className={styles.tooltipData}
                />
              )}
              <TooltipContentRanges range={range} count={index} />
            </div>
          )}
          tooltipContainerRef={tooltipContainerRef}
        />
      )}
      <div className={styles.loadMore}>
        <LoadMoreButton
          onLoadMore={onLoadMore}
          isLoading={fetchingMore}
          hasMore={hasMore}
          scrollContainer={container}
          event={'click'}
          type={'link'}
        />
      </div>
    </div>
  );
};
ExpiringContracts.displayName = 'ExpiringContracts';

export default ExpiringContracts;

import { useQuery, gql } from '@apollo/client';
import { FeatureFlag, UserOwnership } from '@tendium/prom-types';
import { useMemo } from 'react';
import { useApp, isPaidUser } from 'src/models/auth';
import { ownership } from 'src/models/ownership';

export { FeatureFlag } from '@tendium/prom-types';

const GET_GLOBAL_FEATURE_FLAGS = gql`
  query getGlobalFeatureFlags {
    getGlobalFeatureFlags
  }
`;

export function useFeatureFlag(featureName: FeatureFlag): boolean | null {
  const { user, loading } = useApp();
  const myFeatures = isPaidUser(user) ? user.featureFlags : null;
  const { data: globalFeatures, loading: globalFeaturesLoading } = useQuery<{ getGlobalFeatureFlags: string[] }>(
    GET_GLOBAL_FEATURE_FLAGS,
    {
      skip: loading || !!user
    }
  );
  const meFeature = useMemo(() => !!myFeatures && myFeatures.includes(featureName), [featureName, myFeatures]);
  const globalFeature = useMemo(
    () =>
      !!globalFeatures
        ? globalFeatures.getGlobalFeatureFlags.includes(featureName)
        : globalFeaturesLoading
        ? null
        : false,
    [featureName, globalFeatures, globalFeaturesLoading]
  );

  return useMemo(
    () => (myFeatures?.length ? meFeature : globalFeature),
    [globalFeature, meFeature, myFeatures?.length]
  );
}

export function useMyFeaturesAndOwnership(): { myFeatureFlags: string[]; myOwnership: UserOwnership } {
  const { user } = useApp();

  const myFeatureFlags = useMemo(() => (isPaidUser(user) ? user.featureFlags : []), [user]);
  const myOwnership = ownership.name;

  return useMemo(() => ({ myFeatureFlags, myOwnership }), [myFeatureFlags, myOwnership]);
}

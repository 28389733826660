import React, { JSX, useContext } from 'react';
import { Line } from '@visx/shape';
import { Group } from '@visx/group';
import { inPeriod } from 'src/helpers/dates';
import {
  CIRCLE_BORDER,
  CIRCLE_RADIUS,
  DATE_RANGE_LINE_CIRCLE_COLOR,
  DATE_RANGE_LINE_COLOR,
  DateRangeProps,
  LINE_STROKE_WIDTH,
  SPACE_BETWEEN_CIRCLES
} from '../types';
import { XYChartContext } from '../XYChart/context';
import { getX } from '../helpers';

export function DateRangeLine<T>(props: DateRangeProps<T>): JSX.Element | null {
  const { data, getDateRanges, onMouseEnter, onMouseLeave } = props;

  const context = useContext(XYChartContext);
  if (!context) return null;

  const { xScale, yMax, margin } = context;
  const dates = xScale.domain();
  const y = (yMax - LINE_STROKE_WIDTH) / 2;

  return (
    <>
      <Group>
        {data.map((d, i) => {
          const [start, end] = getDateRanges(d);
          const startDate = inPeriod(start, dates);
          const endDate = inPeriod(end, dates);
          const marginX = CIRCLE_RADIUS * 2 + SPACE_BETWEEN_CIRCLES;
          const startX = startDate ? getX(startDate, xScale) : 0;
          const endX = endDate ? getX(endDate, xScale) : 0;
          const lineEndX = endX ? endX - (i !== data.length - 1 ? marginX : 0) : 0;

          const line = (
            <Line
              from={{ x: startX, y }}
              to={{ x: lineEndX, y }}
              stroke={DATE_RANGE_LINE_COLOR}
              strokeWidth={LINE_STROKE_WIDTH}
            />
          );
          const circles = [startX, endX].map((x, j) => {
            const cx = j === 1 && i !== data.length - 1 ? (x ? x - marginX : 0) : x;
            return (
              <circle
                key={`dr-circle-${i}-${j}`}
                cy={y}
                cx={cx}
                r={CIRCLE_RADIUS}
                fill="white"
                stroke={DATE_RANGE_LINE_CIRCLE_COLOR}
                strokeWidth={CIRCLE_BORDER}
                onMouseLeave={() => {
                  onMouseLeave?.();
                }}
                onMouseEnter={() => {
                  onMouseEnter?.(
                    {
                      tooltipData: d,
                      tooltipTop: y - margin.top + margin.bottom + CIRCLE_RADIUS * 2,
                      tooltipLeft: cx + margin.left
                    },
                    i
                  );
                }}
              />
            );
          });
          return (
            <React.Fragment key={`dr-${i}`}>
              {line}
              {circles}
            </React.Fragment>
          );
        })}
      </Group>
    </>
  );
}

export default DateRangeLine;

import React, { FC, KeyboardEvent, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { Form, Input } from 'src/common';
import { IBoxFieldUrl } from 'src/models/procurements/Tender/types';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { IBlockEditProps } from 'src/shared/Tender/Boxes/types';
import ValueActions from '../../ValueActions';
import classNames from 'classnames';

interface IFormData {
  url: string;
  title: string;
}

export interface IProps extends IBlockEditProps<IBoxFieldUrl> {}
export const EditableBlockLink: FC<IProps> = ({ onChange, onFinish, field, containerRef, className }) => {
  const { id: fieldId, title, url } = field;
  const { t } = useTranslation();
  const [form] = useForm();

  const onChangeValues = useCallback(() => {
    const value: IFormData = form.getFieldValue(fieldId);

    value.title !== field.title || value.url !== field.url
      ? onChange && onChange(field.update(value.url, value.title))
      : onFinish && onFinish();
  }, [field, fieldId, form, onChange, onFinish]);

  const onSubmit = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const eventTypes = ['click', 'mousedown', 'touchstart'];
      if ((e.key === 'Enter' && !e.shiftKey) || eventTypes.includes(e.type)) {
        onChangeValues();
      }
    },
    [onChangeValues]
  );

  const initialValues = useMemo(() => {
    return {
      [fieldId]: {
        url,
        title
      }
    };
  }, [fieldId, url, title]);

  const onCancel = useCallback(() => {
    form.resetFields();
    onFinish && onFinish();
  }, [form, onFinish]);

  return (
    <div ref={containerRef} className={className}>
      <Form
        form={form}
        initialValues={initialValues}
        layout={'vertical'}
        className={classNames(styles.container, styles.fields, styles.linkFields)}
      >
        <div className={styles.field}>
          <Form.Item name={[fieldId, 'title']}>
            <Input onPressEnter={e => onSubmit(e)} placeholder={t('Common.title')} className={styles.control} />
          </Form.Item>
          <ValueActions onChange={onChangeValues} onCancel={onCancel} />
        </div>
        <div className={styles.field}>
          <Form.Item
            name={[fieldId, 'url']}
            className={styles.withErrorMessage}
            rules={[{ type: 'url', message: t('Common.Blocks.incorrectUrl') }]}
          >
            <Input onPressEnter={e => onSubmit(e)} placeholder={t('Common.url')} className={styles.control} />
          </Form.Item>
          <ValueActions onChange={onChangeValues} onCancel={onCancel} />
        </div>
      </Form>
    </div>
  );
};

export default EditableBlockLink;

import { IBidIdentifier } from 'src/models/bids/Bids/types';
import { DndType } from 'src/types/dnd';
import { BidItemType } from '@tendium/prom-types/tender';
import { PageView } from 'src/models/users/Preferences/types';
import { ActivePreviewTab } from '../types';
import {
  AwardStatus,
  BuyerType,
  ContractEndRangeMode,
  ContractEndSearchType,
  JunctionLogic,
  KeywordWidth,
  Language,
  SearchOnlyStatus
} from '@tendium/prom-types';
import { isNumber, isNumbers, isObject, isString } from 'src/helpers';
import { TendersTableItem } from '../TendersViews';
import { IApiTenderHighlight } from '../Preview/types';
import { DocumentNode, TypedDocumentNode } from '@apollo/client';
import { IApiTender, IApiTenderBox, ITenderGeneral, ITenderTimeline } from '../Tender/types';
import { ContractValueRange, MpNewKeyword, MpNewKeywordGroup, isMpNewKeyword } from 'src/models/matchingProfiles/types';
import { ContractEndFilterInput } from '@tendium/prom-types/schema';
import isEqual from 'lodash/isEqual';
import isISOString from 'src/helpers/isISOString';

export const DEFAULT_TENDERS_VIEW = PageView.table;
export const DEFAULT_TENDERS_TAB = ActivePreviewTab.Info;
export const DEFAULT_INBOX_TAB = ActivePreviewTab.Email;

export interface ITendersDndItem {
  type: DndType.TendersItem;
  dndItem: TendersTableItem;
  originIds?: string[];
  originType?: BidItemType;
  bidIds?: IBidIdentifier[];
}

export type TendersSortVars = { key: TendersSortName; sort: TendersSortOrder };
type DateFilter = {
  start: number;
  end: number;
};
export type SupplierOrg = {
  orgId: string;
  status: AwardStatus;
};
export type SupplierString = {
  orgName: string;
  status: AwardStatus;
};
type TendersKeywordGroup = MpNewKeywordGroup & { groupId?: string };
export type ApiTendersSearchInput = {
  query?: Omit<ApiTendersVars, 'advanced'>;
  advanced?: ApiTendersAdvancedVars;
};
export type ApiTendersVars = {
  amount?: number;
  isStarred?: boolean;
  isUnhandled?: boolean;
  matchingProfileId?: string;
  offset?: number;
  sortOnField?: TendersSortVars;
  advanced?: ApiTendersAdvancedVars;
};
export type ApiTendersAdvancedVars = {
  assignedTo?: string[];
  awarded?: boolean;
  buyerOrgId?: string;
  buyerOrgIds?: string[];
  buyerSearchStrings?: string[];
  contractEnd?: ContractEndFilterInput;
  contractValueRange?: ContractValueRange;
  cpvCodes?: string[];
  cpvCodesExact?: string[];
  documentSearch?: boolean;
  highlight?: boolean;
  keywordWidth?: KeywordWidth;
  filterKeywordLanguages?: Language[];
  highlightKeywordLanguages?: Language[];
  keywordGroups?: TendersKeywordGroup[];
  keywordArgs?: MpNewKeyword[];
  name?: string;
  nutsCodes?: string[];
  nutsCodesExact?: string[];
  onlyFilledData?: boolean;
  onlyStatus?: SearchOnlyStatus;
  procuringAgency?: string;
  procuringAgencyTypes?: BuyerType[];
  publicationDate?: DateFilter;
  search?: string[];
  submissionDeadline?: DateFilter;
  supplierOrgIds?: SupplierOrg[];
  supplierSearchLogic?: JunctionLogic;
  supplierSearchStrings?: SupplierString[];
  contractValueExcludeMissingValue?: boolean;
  profileId?: string;
};

export type StarredTendersVars = Pick<
  TendersVars,
  'amount' | 'offset' | 'sort' | 'search' | 'onlyStatus' | 'awarded' | 'isStarred'
>;
export type AllTendersVars = Pick<
  TendersVars,
  | 'amount'
  | 'offset'
  | 'sort'
  | 'search'
  | 'onlyStatus'
  | 'assignedTo'
  | 'cpvCodes'
  | 'nutsCodes'
  | 'deadline'
  | 'published'
  | 'buyerOrgIds'
  | 'buyerSearchStrings'
  | 'awarded'
>;
export type MpTendersVars = Pick<
  TendersVars,
  'amount' | 'offset' | 'awarded' | 'sort' | 'search' | 'onlyStatus' | 'matchingProfileId' | 'isUnhandled'
>;
export type BuyersTendersVars = Pick<TendersVars, 'amount' | 'offset' | 'sort' | 'onlyStatus' | 'buyerOrgId'>;
export type ExpContractTendersVars = Pick<
  TendersVars,
  | 'amount'
  | 'offset'
  | 'sort'
  | 'search'
  | 'contractValueRange'
  | 'supplierOrgIds'
  | 'supplierSearchStrings'
  | 'buyerOrgIds'
  | 'buyerSearchStrings'
  | 'keywordWidth'
  | 'filterKeywordLanguages'
  | 'highlightKeywordLanguages'
  | 'keywordGroups'
  | 'keywordArgs'
  | 'cpvCodes'
  | 'cpvCodesExact'
  | 'nutsCodes'
  | 'nutsCodesExact'
  | 'profileId'
  | 'supplierSearchLogic'
  | 'contractValueExcludeMissingValue'
  | 'procuringAgencyTypes'
  | 'contractEnd'
>;

export type SupplierProfileTendersVars = Pick<
  TendersVars,
  | 'amount'
  | 'offset'
  | 'sort'
  | 'search'
  | 'contractValueRange'
  | 'supplierOrgIds'
  | 'supplierSearchStrings'
  | 'buyerOrgIds'
  | 'buyerSearchStrings'
  | 'keywordWidth'
  | 'keywordGroups'
  | 'keywordArgs'
  | 'cpvCodes'
  | 'cpvCodesExact'
  | 'nutsCodes'
  | 'nutsCodesExact'
  | 'profileId'
  | 'supplierSearchLogic'
  | 'contractValueExcludeMissingValue'
  | 'procuringAgencyTypes'
  | 'contractEnd'
>;

export type TendersItemCustomData = {
  isCommented: boolean;
  highlights?: IApiTenderHighlight[];
  nextId?: string;
  bid?: IBidIdentifier;
  matchedCpvs?: string[];
};

export interface ApiTendersTimeline<T> extends Pick<ITenderTimeline<T>, 'availableDate' | 'deadline'> {}
export interface ApiTendersGeneral<T>
  extends Pick<ITenderGeneral<T>, 'name' | 'buyerBoxes' | 'contractDurationBoxes' | 'contractValueBoxes'> {}
export type ApiTendersItem = Omit<
  IApiTender,
  'general' | 'timeline' | 'custom' | 'boxes' | 'fileCategories' | 'comments'
> & {
  timeline: ApiTendersTimeline<IApiTenderBox>;
  general: ApiTendersGeneral<IApiTenderBox>;
  rejected: boolean | null;
  score?: number | null;
};

export type TendersItem<TData extends ApiTendersItem> = TData & TendersItemCustomData;

export type ApiTendersResponse<TData> = {
  count: number;
  procurementsWithScore: {
    procurement: TData;
    highlights: IApiTenderHighlight[];
    matchedCpvs: string[];
    score: number | null;
  }[];
};

export type ApiTendersDataFull = {
  getTenders: ApiTendersResponse<ApiTendersItem>;
};
export type ApiTendersData = {
  getSimpleTenders: ApiTendersResponse<ApiTendersItem>;
};
export type UseApiTendersCtxOptions<TVars> = {
  query?: DocumentNode | TypedDocumentNode<ApiTendersData, ApiTendersVars>;
  initVars: TVars;
};

export enum TendersFilterStatus {
  recent = 'Active',
  expired = 'Expired',
  awarded = 'Awarded',
  all = 'All'
}

export const FILTER_TENDERS_STATUS = [
  TendersFilterStatus.recent,
  TendersFilterStatus.expired,
  TendersFilterStatus.awarded,
  TendersFilterStatus.all
];

export enum TendersSortName {
  published = 'Available',
  title = 'Name',
  buyer = 'Buyer',
  deadline = 'Deadline',
  expiration = 'Expiration',
  relevanceScore = 'RelevanceScore'
}
export enum TendersSortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}
export const SORTABLE_TENDERS_COLUMNS = [
  TendersSortName.published,
  TendersSortName.title,
  TendersSortName.buyer,
  TendersSortName.deadline,
  TendersSortName.relevanceScore
];

export type DateRange = [number, number];

export type TendersVars = Omit<ApiTendersVars, 'sortOnField' | 'advanced'> &
  Omit<
    ApiTendersAdvancedVars,
    'submissionDeadline' | 'publicationDate' | 'procuringAgency' | 'search' | 'documentSearch' | 'highlight'
  > & {
    sort?: TendersSortVars;
    published?: DateRange;
    deadline?: DateRange;
    search?: string;
  };

export type MappedTendersTypes = {
  All: AllTendersVars;
  Starred: StarredTendersVars;
  Mp: MpTendersVars;
  ExpContract: ExpContractTendersVars;
  BuyerExpired: BuyersTendersVars;
  BuyerRecent: BuyersTendersVars;
  SupplierProfile: SupplierProfileTendersVars;
};
export type TendersTypes = keyof MappedTendersTypes;
type MapTypesKeys<T> = {
  [P in keyof T]: Array<keyof T[P]>;
};
export type MapTypeKeys<T> = Array<keyof T>;
export type TendersTypesVars = MapTypesKeys<MappedTendersTypes>;
export enum TendersSearchParams {
  sort = 'sort',
  isUnhandled = 'isUnhandled',
  search = 'search',
  name = 'name',
  deadline = 'deadline',
  published = 'published',
  assignedTo = 'assignedTo',
  awarded = 'awarded',
  cpvCodes = 'cpvCodes',
  nutsCodes = 'nutsCodes',
  onlyStatus = 'onlyStatus',
  buyerOrgIds = 'buyerOrgIds',
  buyerSearchStrings = 'buyerSearchStrings',
  profileId = 'profileId',
  contractValueRange = 'contractValueRange',
  supplierOrgIds = 'supplierOrgIds',
  supplierSearchStrings = 'supplierSearchStrings',
  keywordArgs = 'keywordArgs',
  keywordWidth = 'keywordWidth',
  filterKeywordLanguages = 'filterKeywordLanguages',
  highlightKeywordLanguages = 'highlightKeywordLanguages',
  keywordGroups = 'keywordGroups',
  supplierSearchLogic = 'supplierSearchLogic',
  contractValueExcludeMissingValue = 'contractValueExcludeMissingValue',
  procuringAgencyTypes = 'procuringAgencyTypes',
  contractEnd = 'contractEnd'
}
export enum TendersURLQueryParams {
  tenderStatus = 'tenderStatus'
}

export interface UpdateTenderSearchVarsOptions {
  tenderStatus?: TendersFilterStatus;
}

export function isDateRange(u: unknown): u is DateRange {
  return isNumbers(u) && u.length === 2;
}
export function isDateRangeDiff(arg1?: DateRange, arg2?: DateRange): boolean {
  return !isEqual(arg1?.[0], arg2?.[0]) || !isEqual(arg1?.[1], arg2?.[1]);
}
export function isKeywords(u: unknown): u is MpNewKeyword[] {
  return Array.isArray(u) && u.every(keyword => isMpNewKeyword(keyword));
}
export function isKeywordWidth(u: unknown): u is KeywordWidth {
  return isString(u) && Object.values(KeywordWidth).some(c => c === u);
}
function isLanguage(u: unknown): u is Language {
  return isString(u) && Object.values(Language).some(c => c === u);
}
export function isKeywordLanguages(u: unknown): u is Language[] {
  return Array.isArray(u) && u.every(language => isLanguage(language));
}
export function isKeywordGroup(u: unknown): u is TendersKeywordGroup {
  return isObject(u) && 'values' in u && Array.isArray(u.values);
}
export function isKeywordGroups(u: unknown): u is TendersKeywordGroup[] {
  return Array.isArray(u) && u.every(org => isKeywordGroup(org));
}
export function isContractEnd(u: unknown): u is ContractEndFilterInput {
  return (
    isObject(u) &&
    (('relativeStart' in u && isNumber(u.relativeStart)) ||
      ('relativeEnd' in u && isNumber(u.relativeEnd)) ||
      ('start' in u && isISOString(u.start)) ||
      ('end' in u && isISOString(u.end)) ||
      ('searchMode' in u && Object.values(ContractEndSearchType).some(c => c === u.searchMode)) ||
      ('rangeMode' in u && Object.values(ContractEndRangeMode).some(c => c === u.rangeMode)))
  );
}

export function isContractEndDiff<T extends ContractEndFilterInput>(args1?: T, args2?: T): boolean {
  return (
    !isEqual(args1?.rangeMode, args2?.rangeMode) ||
    !isEqual(args1?.searchMode, args2?.searchMode) ||
    !isEqual(args1?.relativeStart, args2?.relativeStart) ||
    !isEqual(args1?.relativeEnd, args2?.relativeEnd) ||
    !isEqual(args1?.start, args2?.start) ||
    !isEqual(args1?.end, args2?.end)
  );
}

export function isContractEndActive<T extends ContractEndFilterInput>(args?: T): boolean {
  return (
    (args?.rangeMode === ContractEndRangeMode.ABSOLUTE && (args?.start !== undefined || args?.end !== undefined)) ||
    (args?.rangeMode === ContractEndRangeMode.RELATIVE &&
      (args?.relativeStart !== undefined || args?.relativeEnd !== undefined))
  );
}

export function isJunctionLogic(u: unknown): u is JunctionLogic {
  return isString(u) && Object.values(JunctionLogic).some(c => c === u);
}
export function isContractValueRange(u: unknown): u is ContractValueRange {
  return (
    isObject(u) &&
    (('currency' in u && isString(u.currency)) || ('min' in u && isNumber(u.min)) || ('max' in u && isNumber(u.max)))
  );
}
export function isAwardStatus(u: unknown): u is AwardStatus {
  return isString(u) && Object.values(AwardStatus).some(c => c === u);
}
export function isSupplierOrg(u: unknown): u is SupplierOrg {
  return (
    isObject(u) && 'orgId' in u && 'status' in u && (isString(u.orgId) || isNumber(u.orgId)) && isAwardStatus(u.status)
  );
}
export function isSupplierOrgs(u: unknown): u is SupplierOrg[] {
  return Array.isArray(u) && u.every(org => isSupplierOrg(org));
}
export function isSupplierString(u: unknown): u is SupplierString {
  return isObject(u) && 'orgName' in u && 'status' in u && isString(u.orgName) && isAwardStatus(u.status);
}
export function isSupplierStrings(u: unknown): u is SupplierString[] {
  return Array.isArray(u) && u.every(org => isSupplierString(org));
}
export function isBuyerType(u: unknown): u is BuyerType {
  return isString(u) && Object.values(BuyerType).some(c => c === u);
}
export function isTendersSortOrder(u: unknown): u is TendersSortOrder {
  return isString(u) && Object.values(TendersSortOrder).some(c => c === u);
}
export function isTendersSortName(u: unknown): u is TendersSortName {
  return isString(u) && Object.values(TendersSortName).some(c => c === u);
}
export function isTendersSort(u: unknown): u is TendersSortVars {
  return isObject(u) && 'key' in u && 'sort' in u && isTendersSortName(u.key) && isTendersSortOrder(u.sort);
}
export function isTendersDndItem(u: unknown): u is ITendersDndItem {
  return isObject(u) && 'type' in u && u.type === DndType.TendersItem;
}
export function isTendersExpirationStatus(u: unknown): u is SearchOnlyStatus {
  return isString(u) && Object.values(SearchOnlyStatus).some(c => c === u);
}

export type ProfileDiffs = {
  isKeywordsMpDiff: boolean;
  isKeywordGroupsMpDiff: boolean;
  isKeywordWidthMpDiff: boolean;
  isKeywordLanguageDiff: boolean;
  isSuppliersMpDiff: boolean;
  isBuyersMpDiff: boolean;
  isContractValuesMpDiff: boolean;
  isCpvsMpDiff: boolean;
  isNutsMpDiff: boolean;
  isContractEndMpDiff: boolean;
};

// Only enable Västra Götalandsregionen(2321000131) as a buyer when Analytics_Pages_Demo is acitve
export const DEMO_PARAMS: Partial<TendersVars> = { buyerOrgIds: ['2321000131'] };
